<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="max-h-screen3/5 overflow-auto relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="['bg-indigo-100']" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                <CubeTransparentIcon :class="['text-indigo-600']" class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:mr-4">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Tworzenie nowego produktu</DialogTitle>
                <div class="mt-2">
                  <!-- <p class="text-sm text-gray-500">
                    <input type="text" name="categoryName" id="categoryName" v-model="this.category.categoryName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa kategorii" />
                    <span v-if="this.error" class="text-xs text-red-500">Nazwa kategorii nie może być pusta</span>
                  </p> -->
                  <LoadingSpinnerHub v-if="loader"/>
                  <div v-if="!loader" class="border-t border-gray-100">
                    <dl class="divide-y divide-gray-100">
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Typ produktu:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="productType" class="sr-only">Typ produktu</label>
                            <select @change="checkProductType(this.product.productType)" id="location" v-model="this.product.productType" name="location" class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <option value="standard">Standard</option>
                                <option value="set">Zestaw</option>
                            </select>
                          </div>
                        </dd>
                      </div>
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Nazwa:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="productName" class="sr-only">Nazwa produktu</label>
                            <input type="text" name="productName" id="productName" v-model="this.product.productName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa produktu" />
                          </div>
                          <p v-if="this.errors.productName" class="text-xs text-red-500 mt-0.5 ml-1">Podaj nazwę produktu.</p>
                        </dd>
                      </div>
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Opis:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="description" class="sr-only">Opis</label>
                            <input type="text" name="description" id="description" v-model="this.product.description" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Opis produktu" />
                          </div>
                          <p v-if="this.errors.description" class="text-xs text-red-500 mt-0.5 ml-1">Opis nie może być pusty.</p>
                        </dd>
                      </div>
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Cena /szt:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div>
                            <label for="unitPrice" class="sr-only">Cena produktu</label>
                            <!-- <input type="text" name="unitPrice" id="unitPrice" v-model="this.product.unitPrice" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Opis produktu" /> -->
                            <div class="flex max-w-lg">
                                <div class="mt-0.5 relative rounded-md shadow-sm">
                                  <input type="number" @change="checkPrice()" v-model="this.product.unitPrice" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Cena za sztukę" aria-describedby="price-currency" />
                                </div>
                                <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                  PLN
                                </div>
                            </div>
                            <p v-if="this.errors.unitPrice" class="text-xs text-red-500 mt-0.5 ml-1">Cena musi być wyższa lub równa 1 PLN.</p>
                          </div>
                        </dd>
                      </div>
                      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Zdjęcie:</dt>
                        <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0 sm:col-span-3" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                    </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p v-if="this.errors.image" class="text-xs text-red-500 mt-0.5 ml-1">Musisz wybrać zdjęcie.</p>
                        </dd>
                      </div>
                      <div v-if="this.product.productType === 'set'" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Produkty do wyboru:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="productType" class="sr-only">Produkty dostępne do wyboru w tym zestawie</label>
                            <fieldset>
                            <legend class="font-semibold leading-6 text-sm text-gray-900">Wybierz dostępne w zestawie</legend>
                            <div class="mt-1 max-h-56 overflow-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                              <div v-for="(product, indx) in this.setProducts" :key="indx" class="relative flex items-start py-3">
                                <div class="min-w-0 flex-1 text-sm leading-6">
                                  <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-5 w-5 rounded-full inline-block mr-3 object-cover object-center" />
                                  <label :for="`product-${product.setProductName}`" class="select-none font-medium text-gray-600">{{ product.setProductName }}</label>
                                </div>
                                <div class="ml-3 flex h-6 items-center">
                                  <input @click="changePickedSetProduct(indx, $event)" v-model="product.picked" :id="`product-${product.setProductName}`" :name="`product-${product.setProductName}`" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          </div>
                          <p v-if="this.errors.availableSetProducts" class="text-xs text-red-500 mt-0.5 ml-1">Dodaj przynajmniej jeden wybór.</p>
                        </dd>
                      </div>
                      <div v-if="this.product.productType === 'set'" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Liczba produktów wybieranych przez klienta:</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <div>
                            <label for="productType" class="sr-only">Liczba produktów wybieranych przez klienta</label>
                            <div>
                              <ul role="list" class="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6">
                                <li @click="this.product.slots.splice(0,1)" v-for="(slot, index) in this.product.slots" :key="index" class="transition ease-in-out duration-200 cursor-pointer opacity-100 hover:opacity-60 col-span-1 flex rounded-md shadow-sm">
                                  <div class="pt-4 pb-2 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                    <div class="pb-2">
                                      <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs text-center">
                                        Wybór<br />klienta<br />nr {{index+1}}
                                      </div>
                                    </div>
                                    <div class="flex flex-1 items-center justify-between truncate">
                                      <div class="flex-shrink-0">
                                        <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-red-400">
                                          <span class="sr-only">Usuń slot</span>
                                          <TrashIcon class="h-5 w-5" aria-hidden="true" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li class="col-span-1 flex justify-center items-start">
                                  <div @click="this.product.slots.push({picked: false, product: null})" class="transition ease-in-out duration-200 cursor-pointer opacity-40 hover:opacity-100 flex flex-1 items-center justify-between truncate rounded-md shadow-sm border border-gray-200 bg-white">
                                    <div class="flex-1 truncate px-4 py-2 text-sm">
                                      <div class="font-medium text-gray-900 hover:text-gray-600">Dodaj slot</div>
                                    </div>
                                    <div class="flex-shrink-0 pr-2">
                                      <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-green-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span class="sr-only">Dodaj slot</span>
                                        <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p v-if="this.errors.slots" class="text-xs text-red-500 mt-0.5 ml-1">Dodaj przynajmniej jeden slot.</p>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!loader" class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" :class="['bg-indigo-600 text-base font-medium hover:bg-indigo-700 focus:ring-indigo-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' ]" @click="createNewProduct">Dodaj nowy produkt</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {db, storage} from "@/firebase/gfbconf.js";
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CubeTransparentIcon, PlusSmIcon, TrashIcon } from '@heroicons/vue/outline'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import axios from 'axios';
export default {
  components: {
    LoadingSpinnerHub,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CubeTransparentIcon,
    PlusSmIcon,
    TrashIcon,
  },
  props: ['categoryId'],
  data() {
    return {
        loader: false,
        setProducts: [],
        fileToUpload: null,
        imgExternalInputValue: "",
        imgPreviewSrc: "",
        showImgInputField: false,
        fileLimitSize: 2097152,
        product: {
          createdDate: null,
          description: '',
          imageRef: null,
          imageUrl: "",
          productName: "",
          productType: 'standard',
          soldAmount: 0,
          unitPrice: 1,
          availableSetProducts: [],
          slots: []
        },
        errors: {
          description: false,
          productName: false,
          unitPrice: false,
          availableSetProducts: false,
          slots: false,
        }
    }
  },
  created(){
  },
  methods: {
    changePickedSetProduct(index, event){
      this.setProducts[index].picked = event.target.checked
      if(event.target.checked === true){
        this.product.availableSetProducts.push(this.setProducts[index])
      }else{
        for (let i = 0; i < this.product.availableSetProducts.length; i++) {
          const element = this.product.availableSetProducts[i];
          if(element.setProductName === this.setProducts[index].setProductName){
            this.product.availableSetProducts.splice(i,1)
          }
        }
      }
    },
    async checkProductType(productType){
      if(productType === 'set'){
        this.loader = true
        let result = await db.collection("setProducts").doc('setProducts').get()
        let data = result.data()
        this.setProducts = data.setProducts
        for (let i = 0; i < this.setProducts.length; i++) {
          const element = this.setProducts[i];
          element.picked = false
        }
        this.loader = false
      }else{
        this.setProducts = []
        this.product.availableSetProducts = []
        this.product.slots = []
      }
    },
    filenameGenerator(length)
            {
                let result           = `prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
    dropFile(e)
    {
        if(e.dataTransfer.files.length>0)
        {
            if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
            {
                if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                {
                    this.fileToUpload = e.dataTransfer.files[0]
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.dataTransfer.files[0]);
                    
                    fileReader.addEventListener("load", () =>
                    {
                        this.imgPreviewSrc = fileReader.result;
                    })
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zdjęcie waży powyżej 2MB",
                        subheader: `Plik jest zbyt ciężki.`,
                        success: false
                    }); 
                }
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Plik nie jest zdjęciem.",
                    subheader: `Plik powinien być zdjęciem.`,
                    success: false
                });   
            }
        }
        
    },
    replaceImgWithExternalLink()
    {
        if(this.imgExternalInputValue.length === 0) return;
        this.clearImg();
        this.product.imageRef = null;
        this.product.imageUrl = this.imgExternalInputValue;
        this.imgPreviewSrc = this.imgExternalInputValue;
        this.imgExternalInputValue = "";
        this.showImgInputField = false;
    },
    toggleOtherSourcePhoto()
    {
        this.clearImg();
        this.product.imageRef = null;
        this.product.imageUrl = "";
        this.showImgInputField = !this.showImgInputField;
    },
    setMainImage()
    {
        if(this.$refs.mainImgUploader.files.length>0)
        {
            if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
            {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                fileReader.addEventListener("load", () =>
                {
                    this.imgPreviewSrc = fileReader.result;
                })
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zdjęcie waży powyżej 2MB",
                    subheader: `Plik jest zbyt ciężki.`,
                    success: false
                });                    
            }
        }
    },
    triggerImgClick()
    {
        this.$refs.mainImgUploader.click();
    },
    clearImg()
    {
        this.imgPreviewSrc = '';
        this.$refs.mainImgUploader.value = "";
    },
    checkPrice()
          {
              if(this.product.unitPrice.length === 0)
              {
                  this.product.unitPrice = 1;
              }
              if(this.product.unitPrice < 1)
              {
                  this.product.unitPrice = 1;
              }
              else
              {
                  this.product.unitPrice = parseFloat(this.product.unitPrice);
              }
          },
    generateId(){
        let uuid = "", i, random;
        for (i = 0; i < 32; i++) {
          random = Math.random() * 16 | 0;
      
          if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-"
          }
          uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
        }
        return uuid;
    },
    errorCheck(){
      this.errors.description = false
      this.errors.productName = false
      this.errors.unitPrice = false
      this.errors.image = false
      this.errors.availableSetProducts = false
      this.errors.slots = false
      let errors = []
      if(this.product.description.length < 1){
        this.errors.description = true
        errors.push('description')
      }
      if(this.product.productName.length < 1){
        this.errors.productName = true
        errors.push('productName')
      }
      if(this.product.unitPrice < 1){
        this.errors.unitPrice = true
        errors.push('unitPrice')
      }
      if(this.imgPreviewSrc.length < 1){
        this.errors.image = true
        errors.push('image')
      }
      if(this.product.productType === 'set'){
        if(this.product.availableSetProducts.length === 0){
          this.errors.availableSetProducts = true
          errors.push('set-availableSetProducts')
        }
        if(this.product.slots.length === 0){
          this.errors.slots = true
          errors.push('set-slots')
        }
      }
      return errors
    },
    async createNewProduct(){
        let errors = this.errorCheck()
        if(errors.length === 0){
          this.loader = true
          if(this.fileToUpload === null){
            this.fileToUpload = this.$refs.mainImgUploader.files[0]
          }
          if(this.fileToUpload !== null)
          {
              const storageRef = storage.ref();
              const filename = this.filenameGenerator(10);
              const path = `products/${filename}`;
              const imageRef = storageRef.child(path);
              await imageRef.put(this.fileToUpload);
              const fileUrl = await imageRef.getDownloadURL();
              this.product.imageUrl = fileUrl;
              this.product.imageRef = path;
          }
          let makePublicRefs = []
          makePublicRefs.push(this.product.imageRef)
          let result = await axios.post('https://europe-central2-wbtankrestaurant.cloudfunctions.net/api/makePublic', {
            data: makePublicRefs
          })
          this.product.imageUrl = result.data.data

          let id = this.generateId()
          this.product.createdDate = new Date()
          await db.collection("ProductCategories").doc(this.categoryId).update({
            [`products.${id}`]: this.product
          })
          this.loader = false
          this.emitAccept()
        }
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
