<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div class="flex min-h-full flex-1 flex-col justify-center px-6 pt-6 pb-12 lg:px-8">

                <div @click.prevent="close" class="flex justify-end">
                  <XIcon class="transition-all duration-300 w-6 h-6 cursor-pointer text-gray-600 hover:opacity-40" />
                </div>
        

                <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                  <img class="mx-auto h-10 w-auto" src="https://firebasestorage.googleapis.com/v0/b/wbtankrestaurant.appspot.com/o/logo%20(1).png?alt=media&token=fdeaae4e-9662-4ab7-bbe0-0c4c73b7432d" alt="Lauron" />
                  <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Wpisz swój email oraz hasło,<br> aby się zalogować.</h2>
                </div>

                <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form class="space-y-6">
                    <div>
                      <label for="email" class="block text-sm font-medium leading-6 text-gray-900">E-mail</label>
                      <div class="mt-2">
                        <input id="email" name="email" type="email" autocomplete="email" required="" v-model="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Hasło</label>
                      </div>
                      <div class="mt-2">
                        <input id="password" name="password" type="password" autocomplete="current-password" required="" v-model="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                      </div>
                    </div>

                    <div>
                      <button @click.prevent="logInUser" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Zaloguj się</button>
                    </div>

                    <Transition name="fade">
                    <!-- <div v-if="error" class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div> -->
                        <!-- <div class="ml-3">
                          <p class="text-sm text-yellow-700">
                            {{errorMessage}}. -->
                            <!-- {{ ' ' }}
                            <a href="#" class="font-medium text-yellow-700 underline hover:text-yellow-600">Upgrade your account to add more credits.</a> -->
                          <!-- </p>
                        </div>
                      </div>
                    </div> -->
                      <div v-if="error" class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <ExclamationIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">Coś poszło nie tak w trakcie procesu logowania. Podejmij następujące akcje:</h3>
                            <div class="mt-2 text-sm text-red-700">
                              <ul role="list" class="list-disc space-y-1 pl-5">
                                <li>Sprawdź e-mail lub hasło.</li>
                                <li>Sprawdź połączenie z internetem.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Transition>

                    <div class="text-xs font-semibold">
                      &copy; Powered by <a href="https://complaia.systems/" class="text-purple-500 hover:text-purple-400">Complaia Systems</a>.
                    </div>
                  </form>

                </div>
              </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, XIcon } from '@heroicons/vue/outline'
export default {
  data() {
    return {
      open: true,
      email: '',
      password: '',
      error: false,
    }
  },
  components: {
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, ExclamationIcon, XIcon
  },
  methods: {
    async logInUser()
    {
      this.error = false;
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.error = false;
        this.close()
      } catch (err) {
        this.error = true;
      }
    },
    emitClose()
    {
      setTimeout(() => {
        this.$emit("close");
      }, 500);
    },
    emitAccept()
    {
      this.$emit("accept");
      if(this.closeModalAfterAccept) {
        this.$emit("close");
      }
    },
    close()
    {
      this.emitClose();
      this.open = false;
    }
  },

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
opacity: 0;
}
</style>