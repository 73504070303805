<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 px-4 sm:px-0 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" class="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="w-0 flex-1 p-6">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <img class="h-8 w-8 sm:h-10 sm:w-10 object-cover object-center rounded-full" :src="$store.state.orderSupportNotification.imageUrl" alt="" />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-xs sm:text-sm font-medium text-gray-900">{{$store.state.orderSupportNotification.name}}</p>
                <p class="text-xs sm:text-sm text-gray-500">Chciałbyś zmodyfikować swoje zamówienie?</p>
                <p v-if="($store.state.orderSupportNotification.phone !== undefined && $store.state.orderSupportNotification.phone.length > 0) || ($store.state.orderSupportNotification.mail !== undefined && $store.state.orderSupportNotification.mail.length > 0)" class="text-xs sm:text-sm text-gray-700 font-medium border-t border-gray-200 mt-2 pt-2">Skontaktuj się ze mną!</p>
                <p class="text-xs sm:text-sm text-gray-700 font-medium">
                    <!-- <span :class="['flex items-center transition ease-in-out duration-200', order.created.phone !== undefined && order.created.phone.length > 0 ? 'text-indigo-400 hover:text-indigo-600 cursor-pointer' : 'text-gray-500 pointer-events-none']" @click.prevent="callFunction(order.created.phone)"> -->
                    <span v-if="$store.state.orderSupportNotification.phone !== undefined && $store.state.orderSupportNotification.phone.length > 0" :class="['flex items-center transition ease-in-out duration-200', 'text-indigo-400 hover:text-indigo-600 cursor-pointer']" @click.prevent="callFunction($store.state.orderSupportNotification.phone)">
                        <PhoneOutgoingIcon class="h-4 w-4 mr-1" aria-hidden="true" />
                        {{$store.state.orderSupportNotification.phone}}
                    </span>
                </p>
                <p class="text-xs sm:text-sm truncate text-gray-500 font-medium">
                    <span class="flex items-center" v-if="$store.state.orderSupportNotification.mail !== undefined && $store.state.orderSupportNotification.mail.length > 0">
                    <AtSymbolIcon class="h-4 w-4 mr-1" aria-hidden="true" />
                    {{$store.state.orderSupportNotification.mail}}
                </span></p>
                <span v-if="$store.state.orderSupportNotification.orderId !== undefined && $store.state.orderSupportNotification.orderId.length > 0" @click="openOrder($store.state.orderSupportNotification.orderId)" class="mt-2 sm:mt-1 transition ease-in-out duration-200 flex items-center cursor-pointer font-medium text-xs text-purple-500 hover:text-purple-900"><ReceiptRefundIcon class="h-4 w-4 mr-1" aria-hidden="true" /> Powrót do oferty</span>
              </div>
            </div>
          </div>
          <!-- <div class="flex border-l border-gray-200">
            <button type="button" @click="show = false" class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">Twoja oferta</button>
          </div> -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Notification from '../components/Notification.vue';
import { PhoneOutgoingIcon,AtSymbolIcon,ReceiptRefundIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    Notification,
    PhoneOutgoingIcon,
    XIcon,
    AtSymbolIcon,
    ReceiptRefundIcon
  },
  setup() {
    const show = ref(true)

    return {
      show,
    }
  },
  methods:{
    openOrder(id){
      this.$router.push(`/klient/zamowienie?id=${id}`)
    },
    callFunction(phoneNumber){
        let indexOfW = phoneNumber.indexOf('w')
        if(indexOfW !== -1){
            let callNumber = phoneNumber.slice(0, indexOfW)
            window.open(`tel:${callNumber}`, "_self")
        }else{
            window.open(`tel:${phoneNumber}`, "_self")
        }
    },
  },
}
</script>