<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full">
    <Transition name="fade">
    <Disclosure as="nav" class="border-b border-gray-200 bg-white" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
          <div class="flex">
            <div class="flex flex-shrink-0 items-center">
              <img class="block h-8 w-auto lg:hidden" src="https://firebasestorage.googleapis.com/v0/b/wbtankrestaurant.appspot.com/o/logo%20(1).png?alt=media&token=fdeaae4e-9662-4ab7-bbe0-0c4c73b7432d" alt="Your Company" />
              <img class="hidden h-8 w-auto lg:block" src="https://firebasestorage.googleapis.com/v0/b/wbtankrestaurant.appspot.com/o/logo%20(1).png?alt=media&token=fdeaae4e-9662-4ab7-bbe0-0c4c73b7432d" alt="Your Company" />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-2 md:space-x-4 lg:space-x-8">
              <router-link @click.prevent="changeNavigationItem(item)" v-for="item in navigation.filter(nav => nav.logged !== false)" :key="item.name" :to="item.logged ? item.href === this.$route.path ? this.$route.fullPath : item.href : '' " :class="[item.current ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center border-b-2 px-1 pt-1 text-sm sm:text-xs md:text-sm font-medium transition-all duration-300']" :aria-current="item.current ? 'page' : undefined">
                <template v-if="item.logged">
                {{ item.name }}
                </template>
              </router-link>
            </div>
            <div v-if="$store.state.authIsReady" class="hidden sm:-my-px sm:ml-2 md:ml-4 lg:ml-8 sm:flex sm:space-x-2 md:space-x-4 lg:space-x-8">
              <div :class="['border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium transition-all duration-300']">
                <Menu as="div" :class="['relative h-full']" v-if="$store.state.authIsReady">
                  <div class="h-full">
                    <MenuButton class="relative max-w-xs h-full text-sm sm:text-xs md:text-sm">
                      <span class="absolute -inset-1.5" />
                      <span class="sr-only">Open user menu</span>
                      <span class="font-medium flex items-center">Zarządzaj <ChevronDownIcon class="h-4 w-4 ml-1 text-gray-400" aria-hidden="true" /></span>
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/uzytkownicy')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Użytkownicy</div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/kaucje')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Kaucje</div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/produkty_dodatkowe')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Produkty dodatkowe</div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/produkty_zestawowe')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Produkty zestawowe</div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/edycja/galeria')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Edycja galerii</div>
                      </MenuItem>
                      <!-- <MenuItem v-slot="{ active }">
                        <div @click.prevent="this.$router.push('/kalendarz')" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Kalendarz</div>
                      </MenuItem> -->
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <button v-if="!$store.state.authIsReady" @click="openLoginModal()" type="button" class="select-none rounded-full inline-flex items-center gap-x-1.5 bg-indigo-500 px-2.5 py-1 text-sm text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 focus:ring-2 focus:ring-offset-2">
              <LoginIcon class="transform -scale-x-100 h-5 w-5" aria-hidden="true" />
              Zaloguj się
            </button>
            <template v-if="$store.state.authIsReady">
              <button v-if="this.$store.state.cart === null" @click.prevent="prepareCart" type="button" class="relative rounded-full bg-indigo-500 p-1 text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="text-sm px-2 flex items-center">
                  Nowy koszyk
                  <ShoppingCartIcon class="h-4 w-4 ml-1.5" aria-hidden="true" />
                </span>
              </button>
                <Menu v-if="this.$store.state.cart !== null" as="div" class="relative inline-block">
                  <div>
                    <MenuButton class="flex items-center">
                      <div class="relative">
                        <ShoppingCartIcon class="h-6 w-6 text-gray-400 hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                        <span :class="['opacity-70 inline-flex items-center rounded-full bg-blue-100 justify-center h-4 text-xxs font-medium text-blue-700 ring-1 ring-inset ring-blue-400 absolute -right-1.5 -bottom-1.5', $store.state.cart.positions.length < 100 ? 'w-4' : 'w-6' ]">{{$store.state.cart.positions.length < 100 ? $store.state.cart.positions.length : '99+'}}</span>
                        <!-- <span v-if="$store.state.cart.positions.length > 0" class="absolute">{{$store.state.cart.positions.length}}</span> -->
                      </div>
                    </MenuButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems :class="['select-none absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', 'w-96']">
                      <div class="max-h-96 overflow-auto">
                      <template v-for="(meal, index) in $store.state.cart.meals" :key="index">
                        <div class="group flex items-center px-4 py-2 bg-gray-100 select-none">
                          <span class="text-sm font-semibold">
                            {{meal}}
                          </span>
                        </div>
                        <div v-if="checkIfEmpty(meal)" type="button" class="select-none relative block w-full p-2 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                          <ViewGridAddIcon class="mx-auto h-8 w-8 text-gray-200"></ViewGridAddIcon>
                          <span class="mt-2 block text-xs font-medium text-gray-300"> Ten posiłek jest pusty </span>
                        </div>
                        <MenuItem as="div" v-for="(prod, index) in $store.state.cart.positions" :key="index" v-slot="{ active }">
                          <div v-if="meal === prod.meal" class="">
                            <div :class="[active ? 'text-gray-900' : 'text-gray-700', 'group flex justify-between items-center px-4 py-2 text-sm']">
                              <div class="font-semibold flex items-center pr-1">
                                <img :src="prod.product.imageUrl" class="h-12 w-12 rounded-full inline-block mr-3 object-cover object-center" />
                                <div class="flex flex-col">
                                    <span class="font-normal">{{prod.product.productName}}</span>
                                    <div class="flex">
                                      <span>szt: {{prod.quantity}}</span>
                                      <span class="ml-4">cena: {{prod.price}} PLN</span>
                                    </div>
                                </div>
                              </div>
                              <div class="flex justify-end">
                                <MinusCircleIcon @click.prevent="cartOptions('remove', prod)" v-tooltip="`Usuń produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-red-500 transition-all duration-300" aria-hidden="true" />
                                <PlusCircleIcon @click.prevent="cartOptions('add', prod)" v-tooltip="`Dodaj produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-green-500 transition-all duration-300" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      </template>
                      <MenuItem v-if="$store.state.cart.meals.length === 0" v-slot="{ active }">
                        <span :class="[active ? 'text-gray-700' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
                          <ExclamationIcon class="mr-3 h-8 w-8 text-gray-400" aria-hidden="true" />
                          Brak dodanych posiłków do koszyka.
                        </span>
                      </MenuItem>
                      </div>
                      <div class="w-full rounded-b-md">
                        <div @click.prevent="visitCart()" class="transition ease-in-out duration-200 flex cursor-pointer text-white justify-center items-center py-3 bg-indigo-500 rounded-b-md hover:opacity-75">
                          <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                          <span v-if="$store.state.cart.meals.length > 0">Sprawdź koszyk</span><span v-else>Dodaj posiłki</span>
                        </div>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
            </template>

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3" v-if="$store.state.authIsReady">
              <div>
                <MenuButton class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">Open user menu</span>
                  <img v-if="this.$store.state.userData.userImage === undefined || this.$store.state.userData.userImage === null || this.$store.state.userData.userImage.imageUrl.length < 1" class="h-8 w-8 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.$store.state.userData.name} ${this.$store.state.userData.surname}`" alt="" />
                  <img v-if="this.$store.state.userData.userImage !== undefined && this.$store.state.userData.userImage !== null && this.$store.state.userData.userImage.imageUrl.length > 0" class="h-8 w-8 object-cover object-center rounded-full" :src="`${this.$store.state.userData.userImage.imageUrl}`" alt="" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <a @click.prevent="logout" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">Wyloguj się</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <template v-if="$store.state.authIsReady">
              <button v-if="this.$store.state.cart === null" @click.prevent="prepareCart" type="button" class="relative mr-2 rounded-full bg-indigo-500 p-1 text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="text-sm px-2 flex items-center">
                  Nowy koszyk
                  <ShoppingCartIcon class="h-4 w-4 ml-1.5" aria-hidden="true" />
                </span>
              </button>
                <Menu v-if="this.$store.state.cart !== null" as="div" class="relative inline-block mr-2">
                  <div>
                    <MenuButton class="flex items-center">
                      <div class="relative">
                        <ShoppingCartIcon class="h-5 w-5 text-gray-400 hover:text-gray-500 transition-all duration-300" aria-hidden="true" />
                          <span :class="['opacity-70 inline-flex items-center rounded-full bg-blue-100 justify-center h-4 text-xxs font-medium text-blue-700 ring-1 ring-inset ring-blue-400 absolute -right-1.5 -bottom-1.5', $store.state.cart.positions.length < 100 ? 'w-4' : 'w-6' ]">{{$store.state.cart.positions.length < 100 ? $store.state.cart.positions.length : '99+'}}</span>
                      </div>
                    </MenuButton>
                  </div>

                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems :class="['select-none absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', $store.state.cart.positions.length === 0 ? 'w-56' : 'w-72']">
                      <div class="max-h-96 overflow-auto">
                      <template v-for="(meal, index) in $store.state.cart.meals" :key="index">
                        <div class="group flex items-center px-4 py-2 bg-gray-100 select-none">
                          <span class="text-sm font-semibold">
                            {{meal}}
                          </span>
                        </div>
                        <div v-if="checkIfEmpty(meal)" type="button" class="select-none relative block w-full p-2 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                          <ViewGridAddIcon class="mx-auto h-8 w-8 text-gray-200"></ViewGridAddIcon>
                          <span class="mt-2 block text-xs font-medium text-gray-300"> Ten posiłek jest pusty </span>
                        </div>
                        <MenuItem as="div" v-for="(prod, index) in $store.state.cart.positions" :key="index" v-slot="{ active }">
                          <div v-if="meal === prod.meal" class="">
                            <div :class="[active ? 'text-gray-900' : 'text-gray-700', 'group flex justify-between items-center px-4 py-2 text-sm']">
                              <div class="font-semibold flex items-center">
                                <img :src="prod.product.imageUrl" class="h-12 w-12 rounded-full inline-block mr-3 object-cover object-center" />
                                <div class="flex flex-col">
                                    <span class="font-normal">{{prod.product.productName}}</span>
                                    <div class="flex">
                                      <span>szt: {{prod.quantity}}</span>
                                      <span class="ml-4">cena: {{prod.price}} PLN</span>
                                    </div>
                                </div>
                              </div>
                              <div class="flex justify-end">
                                <PlusCircleIcon @click.prevent="cartOptions('add', prod)" v-tooltip="`Dodaj produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-green-500 transition-all duration-300" aria-hidden="true" />
                                <MinusCircleIcon @click.prevent="cartOptions('remove', prod)" v-tooltip="`Usuń produkt.`" class="outline-none h-6 w-6 cursor-pointer text-gray-400 hover:text-red-500 transition-all duration-300" aria-hidden="true" />
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      </template>
                      <MenuItem v-if="$store.state.cart.meals.length === 0" v-slot="{ active }">
                        <span :class="[active ? 'text-gray-700' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
                          <ExclamationIcon class="mr-3 h-8 w-8 text-gray-400" aria-hidden="true" />
                          Brak dodanych posiłków do koszyka.
                        </span>
                      </MenuItem>
                      </div>
                      <div class="w-full rounded-b-md">
                        <div @click.prevent="visitCart()" class="transition ease-in-out duration-200 flex cursor-pointer text-white justify-center items-center py-3 bg-indigo-500 rounded-b-md hover:opacity-75">
                          <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                          <span v-if="$store.state.cart.meals.length > 0">Sprawdź koszyk</span><span v-else>Dodaj posiłki</span>
                        </div>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
            </template>
            <DisclosureButton class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="space-y-1 pb-3 pt-2">
          <router-link @click.prevent="changeNavigationItem(item)" v-for="item in navigation.filter(nav => nav.logged !== false)" :key="item.name" :to="item.logged ? item.href === this.$route.path ? this.$route.fullPath : item.href : ''" :class="[item.current ? 'border-indigo-500 bg-indigo-50 text-indigo-700' : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800', 'block border-l-4 py-2 pl-3 pr-4 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
            <template v-if="item.logged">
            {{ item.name }}
            </template>
          </router-link>
        </div>
        <div class="border-t border-gray-200 pb-3 pt-4">
          <div v-if="$store.state.authIsReady" class="flex items-center px-4">
            <div class="flex-shrink-0">
              <!-- <img class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.$store.state.userData.name} ${this.$store.state.userData.surname}`" alt="" /> -->
              <img v-if="this.$store.state.userData.userImage === undefined || this.$store.state.userData.userImage === null || this.$store.state.userData.userImage.imageUrl.length < 1" class="h-10 w-10 rounded-full" :src="`https://api.dicebear.com/7.x/initials/svg?seed=${this.$store.state.userData.name} ${this.$store.state.userData.surname}`" alt="" />
                  <img v-if="this.$store.state.userData.userImage !== undefined && this.$store.state.userData.userImage !== null && this.$store.state.userData.userImage.imageUrl.length > 0" class="h-10 w-10 object-cover object-center rounded-full" :src="`${this.$store.state.userData.userImage.imageUrl}`" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">{{ $store.state.userData.name + ' ' + $store.state.userData.surname }}</div>
              <div class="text-sm font-medium text-gray-500">{{ $store.state.userData.contact.email }}</div>
            </div>
          </div>
          <div :class="['space-y-1', $store.state.authIsReady ? 'mt-3' : '']">
            <DisclosureButton v-if="$store.state.authIsReady" @click.prevent="logout" as="div" class="block px-4 py-2 cursor-pointer text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"> Wyloguj się
            </DisclosureButton>
            <DisclosureButton v-if="!$store.state.authIsReady" @click.prevent="openLoginModal()" as="div" class="block px-4 py-2 cursor-pointer text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"> Zaloguj się
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
    </Transition>

    <header class="bg-white shadow" v-if="$route.path === '/'">
      <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <div v-if="!loadingCategories" class="text-3xl font-bold flex flex-wrap gap-2 tracking-tight text-gray-900">
          <button @click="changeCategory(category)" v-for="(category, index) in categories" :key="index" type="button" :class="{'transition ease-in-out duration-200 rounded-full px-4 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset': true, 'bg-indigo-500 ring-indigo-600 text-white cursor-default pointer-events-none': category.categoryName === current.categoryName, 'bg-white ring-indigo-300 text-gray-900 hover:bg-indigo-400 hover:text-white': category.categoryName !== current.categoryName}">{{category.categoryName}}</button>
          <button v-if="$store.state.authIsReady" @click="openProductModal()" type="button" :class="{'transition ease-in-out duration-200 rounded-full px-4 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset': true, 'ring-blue-600 bg-blue-500 text-white hover:opacity-70': true}">Zarządzaj produktami</button>
        </div>
        <div v-else>
          <LoaderData class="py-4" />
        </div>
      </div>
    </header>

    <header class="bg-white shadow" v-if="$route.path === '/uzytkownicy' && $store.state.authIsReady">
      <div v-if="$store.state.userData.admin !== undefined && $store.state.userData.admin === true" class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
        <div v-if="!loadingCategories" class="text-3xl font-bold tracking-tight text-gray-900">
          <!-- {{$store.state.userData.admin}} -->
          <button v-if="$store.state.authIsReady" @click.prevent="showAddModal = true" type="button" :class="{'transition ease-in-out duration-200 rounded-full px-4 py-2.5 text-sm font-semibold shadow-sm ring-1 ring-inset ml-2': true, 'bg-white ring-indigo-300 text-gray-900 hover:bg-indigo-400 hover:text-white': true}">Dodaj użytkownika</button>
        </div>
        <div v-else>
          <LoaderData class="py-4" />
        </div>
      </div>
    </header>

    <main v-if="$route.path !== '/'">
      <div class="mx-auto max-w-7xl">
        <!-- Your content -->
        <div class="">
          <div class="mx-auto max-w-2xl px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
              <router-view></router-view>
          </div>
        </div>
      </div>
    </main>

    <div v-if="$route.path === '/'">
      <main>
        <div class="mx-auto max-w-7xl">
          <!-- Your content -->

          <div class="">
            <div class="mx-auto max-w-full px-4 py-10 sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 class="sr-only">Products</h2>

              <div v-if="!loadingCategories" class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                <div v-for="product in Object.keys(current.categoryProducts)" :key="product.id" class="relative flex flex-col rounded-lg border border-gray-200 bg-white">
                  <div class="relative cursor-pointer group aspect-h-4 aspect-w-3 bg-gray-200 aspect-none h-96">
                      <img v-show="current.categoryProducts[product].imageLoaded" @load="current.categoryProducts[product].imageLoaded = true" :src="current.categoryProducts[product].imageUrl" class="h-full rounded-t-md w-full object-cover object-center" />
                      <div v-if="!current.categoryProducts[product].imageLoaded" class="relative flex justify-center items-center w-full h-full">
                      <LoaderData />
                      </div>
                      <div v-if="current.categoryProducts[product].productType !== undefined && current.categoryProducts[product].productType === 'set'" :class="['transition ease-in-out duration-300 absolute left-0 top-0 h-full w-full']">
                        <div class="relative flex flex-col justify-start items-end w-full h-full">
                          <div class="opacity-85 px-2 py-0.5 mt-3 mr-2 text-xs bg-gradient-to-r from-green-400 to-green-700 text-white font-medium border border-green-800 rounded-lg">
                            Dopasowany do Ciebie!
                          </div>
                          <!-- <div class="opacity-90 px-2 py-0.5 m-2 mt-1 text-xs bg-gradient-to-r from-yellow-400 to-yellow-600 text-white font-medium border border-gray-500 rounded-lg">
                            Zestaw
                          </div> -->
                          <!-- <ArrowsExpandIcon class="h-8 w-8 ml-1.5 text-gray-800 opacity-80  pr-2 pt-1" aria-hidden="true" /> -->
                        </div>
                      </div>
                      <div @click.prevent="showFullScreenImg = true, fullScreenImg = current.categoryProducts[product].imageUrl" :class="['transition ease-in-out duration-300 absolute left-0 top-0 h-full w-full opacity-0 group-hover:opacity-100']">
                        <div class="relative flex justify-end items-end w-full h-full">
                          <div class="absolute left-0 top-0 w-full h-full bg-white opacity-40"></div>
                          <ArrowsExpandIcon class="h-8 w-8 ml-1.5 text-gray-800 opacity-80  pr-2 pb-1" aria-hidden="true" />
                        </div>
                      </div>
                  </div>
                  <div class="flex flex-1 flex-col space-y-2 p-4">
                    <h3 class="text-sm font-medium text-gray-900">
                        <span aria-hidden="true" />
                        {{ current.categoryProducts[product].productName }}
                    </h3>
                    <p class="text-sm text-gray-500">{{ current.categoryProducts[product].description }}</p>
                    <div v-if="current.categoryProducts[product].productType !== undefined && current.categoryProducts[product].productType === 'set'">
                      <div :class="['border-transparent text-gray-500 hover:text-gray-700', 'inline-flex items-center border-b-2 text-sm font-medium transition-all duration-300']">
                        <Menu as="div" :class="['relative h-full']">
                          <div class="h-full">
                            <MenuButton class="relative max-w-xs h-full text-sm">
                              <span class="absolute -inset-1.5" />
                              <span class="sr-only">Open user menu</span>
                               <p class="text-xs select-none cursor-pointer text-indigo-500 font-medium flex items-center">Produkty dostępne do wyboru <ChevronDownIcon class="h-3 w-3 ml-0.5" aria-hidden="true" /></p>
                            </MenuButton>
                          </div>
                          <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute left-0 z-50 mt-2 w-80 max-h-48 overflow-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <MenuItem v-for="(product, indx) in current.categoryProducts[product].availableSetProducts" :key="indx" v-slot="{ active }">
                                <div @click.stop="showFullScreenImg = true, fullScreenImg = product.imageUrl" :class="[active ? 'bg-gray-100' : '', indx !== 0 ? 'border-t border-gray-200':'', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">
                                  <div class="min-w-0 flex-1 text-sm leading-6">
                                    <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-8 w-8 rounded-full inline-block mr-3 object-cover object-center" />
                                    <span class="font-medium text-xxs text-gray-600">{{ product.setProductName }}</span>
                                  </div>
                                </div>
                              </MenuItem>
                            </MenuItems>
                          </transition>
                        </Menu>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-end">
                      <p class="text-sm italic text-gray-500">Cena:</p>
                      <p class="text-base font-medium text-gray-900">{{ current.categoryProducts[product].unitPrice.toFixed(2) }} PLN /szt</p>
                    </div>
                  </div>
                  <Transition name="fade">
                  <div v-if="$store.state.authIsReady" class="relative w-full border-t border-gray-200 bg-gray-50">
                        <div class="px-4 mb-1 flex justify-between">
                          <div class="py-3 w-7/12">
                            <Listbox :disabled="$store.state.cart === null || ($store.state.cart !== null && $store.state.cart.meals.length === 0)" as="div" class="w-full" v-model="current.categoryProducts[product].pickedMeal">
                              <ListboxLabel class="block text-xs font-medium text-gray-900">Posiłek</ListboxLabel>
                              <div class="relative">
                                <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-2 sm:py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <span :class="['block truncate', current.categoryProducts[product].pickedMeal === 'Brak' ? 'text-red-500 font-medium' : '']">{{ current.categoryProducts[product].pickedMeal }}</span>
                                  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                  <ListboxOptions class="absolute z-10 max-h-60 w-full overflow-auto rounded-b-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption as="template" v-for="meal in (this.$store.state.cart !== null && this.$store.state.cart.meals.length !== 0  ? this.$store.state.cart.meals : this.noCart)" :key="meal" :value="meal" v-slot="{ active, selected }">
                                      <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ meal }}</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      </li>
                                    </ListboxOption>
                                  </ListboxOptions>
                                </transition>
                              </div>
                            </Listbox>
                          </div>
                          <div class="py-3 w-5/12 flex justify-end">
                            <div class="w-10/12">
                              <label class="block text-xs font-medium text-gray-900">Ilość</label>
                              <div class=" sm:mt-0 sm:col-span-2">
                                  <div class="flex max-w-lg">
                                      <div class="mt-0.5 relative rounded-md shadow-sm">
                                      <input type="number" :disabled="$store.state.cart === null || ($store.state.cart !== null && $store.state.cart.meals.length === 0)" @change="checkProductionCost(current,product)" v-model="current.categoryProducts[product].pickedAmount" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                      </div>
                                      <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                        szt.
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div @click.prevent="cartOptions('create', current, product)" v-if="current.categoryProducts[product].productType === undefined || current.categoryProducts[product].productType !== 'set'" :class="['transition ease-in-out duration-200 flex text-white justify-center items-center py-3 bg-indigo-500 rounded-b-md', current.categoryProducts[product].pickedMeal !== 'Brak' ? 'cursor-pointer hover:opacity-75' : 'pointer-events-none opacity-60']">
                        <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                        Dodaj do koszyka
                        <span class="text-xs ml-1.5 mt-0.5">({{(current.categoryProducts[product].unitPrice * current.categoryProducts[product].pickedAmount).toFixed(2)}} PLN)</span>
                      </div>
                      <div @click.prevent="configureSet(current, product)" v-if="current.categoryProducts[product].productType !== undefined && current.categoryProducts[product].productType === 'set'" :class="['transition ease-in-out duration-200 flex text-white justify-center items-center py-3 bg-green-600 rounded-b-md', current.categoryProducts[product].pickedMeal !== 'Brak' ? 'cursor-pointer hover:opacity-75' : 'pointer-events-none opacity-60']">
                        <ShoppingBagIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                        Konfiguruj zestaw
                        <span class="text-xs ml-1.5 mt-0.5">({{(current.categoryProducts[product].unitPrice * current.categoryProducts[product].pickedAmount).toFixed(2)}} PLN)</span>
                      </div>
                      <div v-if="$store.state.cart === null" class="rounded-b-md absolute flex items-center justify-center bottom-0 left-0 w-full h-full">
                        <div class="h-full w-full relative flex justify-center items-center">
                          <div class="bg-white w-full h-full absolute opacity-80"></div>
                          <div class="z-10 flex flex-col">
                            <div class="text-xs mb-1">Twój koszyk nie jest gotowy</div>
                            <button v-if="this.$store.state.cart === null" @click.prevent="prepareCart" type="button" class="transition ease-in-out duration-300 relative rounded-full bg-indigo-500 p-1 text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="text-sm px-2 flex items-center">
                                Przygotuj koszyk
                                <ShoppingCartIcon class="h-4 w-4 ml-1.5" aria-hidden="true" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="$store.state.cart !== null && $store.state.cart.meals.length === 0" class="rounded-b-md absolute flex items-center justify-center bottom-0 left-0 w-full h-full">
                        <div class="h-full w-full relative flex justify-center items-center">
                          <div class="bg-white w-full h-full absolute opacity-80"></div>
                          <div class="z-10 flex flex-col items-center">
                            <div class="text-xs mb-1">Przygotuj listę posiłków</div>
                            <button @click.prevent="this.$router.push('/koszyk')" type="button" class="transition ease-in-out duration-300 relative rounded-full bg-indigo-500 p-1 text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="text-sm px-2 flex items-center">
                                Przygotuj
                                <ShoppingCartIcon class="h-4 w-4 ml-1.5" aria-hidden="true" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                  </Transition>
                </div>
              </div>
              <div v-if="!loadingCategories && Object.keys(current.categoryProducts).length === 0">
                  <EmptyState />
                </div>
              <div v-if="loadingCategories">
                <LoadingSpinnerHub/>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
  <CreateCartModal v-if="showCartModal" @accept="createCart" @close="closeCartModal" />
  <ModalNewUser :show="showAddModal" @closeClientModal="showAddModal = false"></ModalNewUser>
  <LoginModal v-if="loginModalOpen" @close="closeLoginModal" />
  <ProductModal v-if="productModalOpen" @reload="downloadCategories()" @close="closeProductModal"/>
  <transition name="fade">
    <Notification v-if="$store.state.notification.show"></Notification>
  </transition>
  <transition name="fade">
    <OrderSupport v-if="$store.state.orderSupportNotification.show"></OrderSupport>
  </transition>
  <TransitionRoot as="template" :show="showFullScreenImg">
    <Dialog as="div" class="relative z-10" @close="closeFullScreenImg()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl  sm:p-6">
              <div class="sm:flex sm:items-start">
                  <img class="w-full" :src="fullScreenImg" alt="" />
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="closeFullScreenImg()" ref="cancelButtonRef">Zamknij</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="showConfigureSet">
    <Dialog as="div" class="relative z-10" @close="closeFullScreenImg()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl  sm:p-6">
              <div v-if="configureSetItem !== null">
                <!-- {{configureSetItem}} -->
                <!-- <div @click="log(configureSetItem.slots)">
                  Test
                </div> -->
                      <table class="w-full whitespace-nowrap text-left text-sm leading-6">
                          <colgroup>
                          <col class="w-full" />
                          <col />
                          <col />
                          </colgroup>
                          <tbody>
                          <tr class="border-b border-gray-100">
                              <td class="max-w-0 px-0 py-5 align-top">
                              <div class="flex items-center">
                                  <img v-if="configureSetItem.imageUrl !== undefined && configureSetItem.imageUrl.length > 0" :src="configureSetItem.imageUrl" class="h-10 w-10 rounded-full inline-block mr-3 object-cover object-center" />
                                  <div class="w-full pr-8">
                                      <div class="truncate font-medium text-gray-900" v-tooltip="`${configureSetItem.productName !==undefined && configureSetItem.productName.length > 0 ? configureSetItem.productName : '-'}`">{{ configureSetItem.productName !==undefined && configureSetItem.productName.length > 0 ? configureSetItem.productName : '-' }}</div>
                                      <div class="truncate text-gray-500" v-tooltip="`${configureSetItem.description !== undefined && configureSetItem.description.length > 0 ? configureSetItem.description : '-'}`">{{ configureSetItem.description !== undefined && configureSetItem.description.length > 0 ? configureSetItem.description : '-' }}</div>
                                  </div>
                              </div>
                              </td>
                              <td class="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                                <div class="w-28 flex justify-end">
                                  <div class="w-full">
                                    <div>
                                        <div class="flex max-w-lg">
                                            <div class="mt-0.5 relative rounded-md shadow-sm">
                                            <input type="number" @change="checkConfigureSetPickedAmount()" v-model="configureSetItem.pickedAmount" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                            </div>
                                            <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                              szt.
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                  <!-- {{ configureSetItem.pickedAmount !==undefined && configureSetItem.pickedAmount > 0 ? configureSetItem.pickedAmount : '-'}} -->
                              </td>
                              <td class="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                                  <span>{{ (configureSetItem.unitPrice * configureSetItem.pickedAmount).toFixed(2) }}</span> PLN
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <div v-if="configureSetItem !== undefined && configureSetItem.productType === 'set' && configureSetItem.slots !== undefined" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                          <div>
                            <label for="productType" class="sr-only">Liczba produktów wybieranych przez klienta</label>
                            <div>
                              <ul role="list" class="grid gap-5 grid-cols-2 sm:grid-cols-3">
                                <!-- <li v-for="(slot, index) in configureSetItem.slots" :key="index" class="transition ease-in-out duration-200 cursor-pointer opacity-100 hover:opacity-60 col-span-1 flex rounded-md shadow-sm">
                                  <div class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                      <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs text-center">
                                        Wybór<br />klienta<br />nr {{index+1}}
                                      </div>
                                  </div>
                                </li> -->
                                <li v-for="(slot, index) in configureSetItem.slots" :key="index" class="w-full">
                                    <Menu as="div" :class="['relative h-full w-full']">
                                        <MenuButton class="relative w-full">
                                          <div class="transition ease-in-out duration-200 cursor-pointer opacity-100 hover:opacity-60 col-span-1 flex rounded-md shadow-sm">
                                            <div v-if="slot.picked === false" class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                                <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs text-center">
                                                  Wybór<br />klienta<br />nr {{index+1}}
                                                </div>
                                                <span class="text-xxs mt-2 text-blue-500">Wybierz</span>
                                            </div>
                                            <div v-if="slot.picked === true" class="p-4 flex flex-col flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
                                                <!-- <div class="w-16 h-16 rounded-full bg-gray-50 flex items-center justify-center text-xxs">
                                                  Wybór<br />klienta<br />nr {{index+1}}
                                                </div> -->
                                                <img :src="slot.product.imageUrl" class="h-16 w-16 rounded-full inline-block object-cover object-center" />
                                                <span class="text-xxs mt-2">{{slot.product.setProductName}}</span>
                                            </div>
                                          </div>
                                        </MenuButton>
                                      <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                        <MenuItems class="absolute left-0 z-50 mt-2 w-80 max-h-48 overflow-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <MenuItem v-for="(product, indx) in configureSetItem.availableSetProducts" :key="indx" v-slot="{ active }">
                                            <div @click.stop="configureSetItem.slots[index].picked = true, configureSetItem.slots[index].product = product" :class="[active ? 'bg-gray-100' : '', indx !== 0 ? 'border-t border-gray-200':'', 'block px-4 py-2 text-sm text-gray-700 select-none cursor-pointer']">
                                              <div class="min-w-0 flex-1 text-sm leading-6">
                                                <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-8 w-8 rounded-full inline-block mr-3 object-cover object-center" />
                                                <span class="font-medium text-xxs text-gray-600">{{ product.setProductName }}</span>
                                              </div>
                                            </div>
                                          </MenuItem>
                                        </MenuItems>
                                      </transition>
                                    </Menu>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!-- <p v-if="this.errors.slots" class="text-xs text-red-500 mt-0.5 ml-1">Dodaj przynajmniej jeden slot.</p> -->
                        </dd>
                      </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click="cartOptions('createSetItem')" :disabled="checkAllPicked()" type="button" :class="['transition ease-in-out duration-300 inline-flex w-full justify-center text-white rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto', checkAllPicked() ? 'opacity-50 pointer-events-none' : 'hover:opacity-75']" ref="acceptButtonRef">
                  <ShoppingCartIcon class="h-5 w-5 text-gray-100 mr-2" aria-hidden="true" />
                  Dodaj do koszyka
                </button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="closeConfigureSet()" ref="cancelButtonRef">Zamknij</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import CreateCartModal from '../components/SelectModal.vue';
    import ModalNewUser from '../components/ModalNewUser.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
    import LoaderData from '../components/LoaderData.vue';
    import LoginModal from '../components/LoginModal.vue';
    import ProductModal from '@/components/ProductModal.vue';
    import Notification from '@/components/Notification.vue';
    import OrderSupport from '@/components/OrderSupport.vue';
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
    import { ArrowsExpandIcon,PlusCircleIcon, MinusCircleIcon, MenuIcon, XIcon, CheckIcon, ChevronDownIcon,ChevronUpIcon, ShoppingCartIcon, LoginIcon, ViewGridAddIcon, ShoppingBagIcon } from '@heroicons/vue/outline'
    import {db, auth} from "@/firebase/gfbconf.js";
    import { ExclamationIcon, ArchiveIcon, ArrowCircleRightIcon, DocumentDuplicateIcon, HeartIcon, PencilAltIcon, TrashIcon, UserAddIcon } from '@heroicons/vue/solid'
    export default { 
        name: "Template",
        data()
        {
            return {
              showFullScreenImg: false,
              showConfigureSet: false,
              configureSetItem: null,
              fullScreenImg: "",
              noCart: [
                "Brak"
              ],
              showCartModal: false,
              showAddModal: false,
              productModalOpen: false,
              loginModalOpen: false,
              cartShow: false,
              loadingCategories: true,
              current: {
                categoryName: "",
                categoryProducts: null
              },
              categories: [],
              navigation: [
                { name: 'Strona główna', href: '/', current: true, logged: true },
                // { name: 'Użytkownicy', href: '/uzytkownicy', current: false, logged: false },
                { name: 'Galeria', href: '/galeria', current: false, logged: true },
                { name: 'Zamówienia', href: '/zamowienia', current: false, logged: false },
                { name: 'Kalendarz', href: '/kalendarz', current: false, logged: false },
              ],
              userNavigation: [
                { name: 'Sign out', href: '#' },
              ],
              cart: []
            }
        },
        components: {
          OrderSupport,ShoppingBagIcon, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, ArrowsExpandIcon, ViewGridAddIcon, CreateCartModal, PlusCircleIcon, MinusCircleIcon, ExclamationIcon, ModalNewUser, EmptyState, Notification, LoaderData, LoadingSpinnerHub, ProductModal, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, XIcon, MenuIcon, CheckIcon, ChevronDownIcon, ShoppingCartIcon, LoginIcon, LoginModal, ArchiveIcon, ArrowCircleRightIcon, DocumentDuplicateIcon, HeartIcon, PencilAltIcon, TrashIcon, UserAddIcon, ChevronUpIcon
        },
        async created()
        {
          await this.downloadCategories()
          if(this.$route.path === '/' && this.$route.query.kategoria !== undefined && this.$route.query.kategoria.length > 0){
            this.openCategory(this.$route.query.kategoria.replaceAll('_', ' '))
          }
          const loginSubscription = auth.onAuthStateChanged(async (user) => {
            if(user)
            {
              this.$store.commit("setUser", user);
              // Get user data by id from db
              let userCred = await db.collection("UsersData").doc(this.$store.state.user.uid).get();
              if(userCred.exists)
              {
                  this.$store.commit('setUserCredentials', userCred.data());
                  this.$store.commit("setAuthIsReady", true);
                  this.checkLogin();
                  this.$store.commit("resetGlobalLoader");
              }
              else
              {
                  this.$router.push("/");
              }
            }
            else
            {
                if(this.$route.path !== '/' && this.$route.path !== '/galeria' && this.$route.path !== '/klient/zamowienie'){
                  this.$router.push("/");
                }
            }
        })
        for(let i=0; i<this.navigation.length; i++)
            {
              if(this.navigation[i].href !== this.$route.path){
                this.navigation[i].current = false
              }else{
                this.navigation[i].current = true
              }
            }
        },
        watch:{
          '$route.path'(){
            if(this.$route.path === '/'){
              this.downloadCategories()
            }
            for(let i=0; i<this.navigation.length; i++)
            {
              if(this.navigation[i].href !== this.$route.path){
                this.navigation[i].current = false
              }else{
                this.navigation[i].current = true
              }
            }
          }
        },
        methods:
        {
          openCategory(categoryName){
            let found = false
            for (let i = 0; i < this.categories.length; i++) {
              const category = this.categories[i];
              if(category.categoryName === categoryName){
                found = true
                this.changeCategory(category)
              }
            }
            if(found === false){
              this.$router.push('/')
            }
          },
          checkAllPicked(){
            if(this.configureSetItem !== null){
            const toPickAmount = this.configureSetItem.slots.length
            let pickedAmount = 0
            for (let i = 0; i < this.configureSetItem.slots.length; i++) {
              const element = this.configureSetItem.slots[i];
              if(element.picked){
                pickedAmount++
              }
            }
            let toReturn = true
            if(toPickAmount === pickedAmount){
              toReturn = false
            }else{
              toReturn = true
            }
            return toReturn
            }
          },
          closeConfigureSet(){
            this.showConfigureSet = false
            setTimeout(() => {
              this.configureSetItem = null
            }, 300);
          },
          configureSet(current, product){
            this.showConfigureSet = true
            // z zapisywaniem
            // this.configureSetItem = current.categoryProducts[product]

            // bez zapisywania
            this.configureSetItem = JSON.parse(JSON.stringify(current.categoryProducts[product]))
          },
          visitCart(){
            this.$router.push('/koszyk')
            for(let i=0; i<this.navigation.length; i++)
            {
                this.navigation[i].current = false;
            }
          },
          clearCart(){
            this.$store.dispatch("clearWholeCart")
          },
          closeFullScreenImg(){
            this.showFullScreenImg = false
            setTimeout(() => {
              this.fullScreenImg = ''
            }, 300);
          },
          checkIfEmpty(meal){
            if(this.$store.state.cart.positions.length > 0){
              let crntProducts = []
              for (let i = 0; i < this.$store.state.cart.positions.length; i++) {
                let crntProduct = this.$store.state.cart.positions[i]
                if(crntProduct.meal === meal){
                  crntProducts.push(crntProduct)
                }
              }
              if(crntProducts.length > 0){
                return false
              }else{
                return true
              }
            }else{
              return true
            }
          },
          log(log){
            console.log(log)
          },
          createCart(type, meals)
          {
            let payload = {
              type: type,
              meals: meals
            }
            this.$store.dispatch('createCart', payload)
            this.assignMeals()
          },
          assignMeals(){
            for (let i = 0; i < this.categories.length; i++) {
              const element = this.categories[i];
              for (let x = 0; x < Object.keys(element.products).length; x++) {
                const productId = Object.keys(element.products)[x]
                const product = element.products[productId];
                if(this.$store.state.cart === null){
                  product.pickedMeal = "Brak"
                }else{
                  product.pickedMeal = this.$store.state.cart.meals[0]
                }
              }
            }
          },
          prepareCart()
          {
            this.showCartModal = true
          },
          closeCartModal(){
            this.showCartModal = false
          },
          cartOptions(value, current, product)
          {
            if(value === 'createSetItem'){
              this.$store.commit('setNotification',{
                  show: true,
                  head: "Dodano produkt do koszyka!",
                  subheader: `${this.configureSetItem.pickedAmount}x ${this.configureSetItem.productName} do posiłku ${this.configureSetItem.pickedMeal}`,
                  success: true
              }); 
              let currentProduct = {
                product: this.configureSetItem,
                id: this.configureSetItem.id,
                meal: this.configureSetItem.pickedMeal
              }
              // this.createCart();
              this.$store.dispatch('addToCart', currentProduct)
              this.closeConfigureSet()
            }
            if(value === 'create')
            {
              this.$store.commit('setNotification',{
                        show: true,
                        head: "Dodano produkt do koszyka!",
                        subheader: `${current.categoryProducts[product].pickedAmount}x ${current.categoryProducts[product].productName} do posiłku ${current.categoryProducts[product].pickedMeal}`,
                        success: true
                    }); 
              let currentProduct = {
                product: current.categoryProducts[product],
                id: current.categoryProducts[product].id,
                meal: current.categoryProducts[product].pickedMeal
              }
              // this.createCart();
              this.$store.dispatch('addToCart', currentProduct)
            }
            else if(value === 'remove')
            {
              this.$store.dispatch('removeFromCart', current)
            }
            else if(value === 'add')
            {
              this.$store.dispatch('addSingleToCart', current)
            }
          },
          changeNavigationItem(item)
          {
            for(let i=0; i<this.navigation.length; i++)
            {
              let isFound = false;
              if(this.navigation[i].name === item.name)
              {
                isFound = true;
                this.navigation[i].current = true;
              }
              if(!isFound)
              {
                this.navigation[i].current = false;
              }
            }
          },
          checkLogin()
          {
            if(this.$store.state.authIsReady)
            {
              for(let i=0; i<this.navigation.length; i++)
              {
                if(!this.navigation[i].logged && this.navigation[i].name !== 'Strona główna')
                {
                  this.navigation[i].logged = true;
                }
              }
            }
            else
            {
              for(let i=0; i<this.navigation.length; i++)
              {
                if(this.navigation[i].logged && this.navigation[i].name !== 'Strona główna')
                {
                  this.navigation[i].logged = false;
                }
                else
                {
                  this.navigation[i].current = true;
                }
              }
            }
          },
          async logout()
          {
            try
            {
              await this.$store.dispatch("logout");
              for(let i=0; i<this.navigation.length; i++)
              {
                if(this.navigation[i].href === '/')
                {
                  this.navigation[i].current = true;
                }else{
                  this.navigation[i].current = false;
                }
              }
              this.checkLogin();
            }
            catch (err)
            {
              console.log(err.message);
            }
          },
          closeLoginModal(){
            this.loginModalOpen = false
          },
          openLoginModal(){
            this.loginModalOpen = true
          },
          closeProductModal(){
            this.productModalOpen = false
          },
          openProductModal(){
            this.productModalOpen = true
          },
          checkProductionCost(current,product)
          {
              current.categoryProducts[product].pickedAmount = Math.round(current.categoryProducts[product].pickedAmount)
              if(current.categoryProducts[product].pickedAmount.length === 0)
              {
                  current.categoryProducts[product].pickedAmount = 1;
              }
              if(current.categoryProducts[product].pickedAmount < 1)
              {
                  current.categoryProducts[product].pickedAmount = 1;
              }
              else
              {
                  current.categoryProducts[product].pickedAmount = parseFloat(current.categoryProducts[product].pickedAmount);
              }
          },
          checkConfigureSetPickedAmount()
          {
              this.configureSetItem.pickedAmount = Math.round(this.configureSetItem.pickedAmount)
              if(this.configureSetItem.pickedAmount.length === 0)
              {
                  this.configureSetItem.pickedAmount = 1;
              }
              if(this.configureSetItem.pickedAmount < 1)
              {
                  this.configureSetItem.pickedAmount = 1;
              }
              else
              {
                  this.configureSetItem.pickedAmount = parseFloat(this.configureSetItem.pickedAmount);
              }
          },
          changeCategory(category){
            this.$router.push(`?kategoria=${category.categoryName.replaceAll(' ', '_')}`)
            this.current.categoryName = category.categoryName
            this.current.categoryProducts = category.products
          },
          async downloadCategories(){
            this.loadingCategories = true
            this.categories = []
            this.current.categoryName = ""
            this.current.categoryProducts = null
            let categoriesDocs = await db.collection("ProductCategories").get()
            for (let i = 0; i < categoriesDocs.docs.length; i++) {
              const element = categoriesDocs.docs[i].data();
              let productsToSort = []
              for (let x = 0; x < Object.keys(element.products).length; x++) {
                const productId = Object.keys(element.products)[x]
                const product = element.products[productId];
                product.pickedAmount = 1
                if(this.$store.state.cart === null || (this.$store.state.cart !== null && this.$store.state.cart.meals.length === 0)){
                  product.pickedMeal = "Brak"
                }else{
                  product.pickedMeal = this.$store.state.cart.meals[0]
                }
                product.imageLoaded = false
                product.id = productId
                productsToSort.push(product)
              }
              productsToSort.sort(function(a,b) {
                  return a.soldAmount - b.soldAmount;
              });
              productsToSort.reverse()
              element.products = productsToSort
              this.categories.push(element)
            }
            this.categories.sort((a,b) => a.sortIndex - b.sortIndex)
            if(this.categories[0] !== undefined){
              this.current.categoryName = this.categories[0].categoryName
              this.current.categoryProducts = this.categories[0].products
              if(this.$route.path === '/'){
                this.$router.push(`?kategoria=${this.categories[0].categoryName.replaceAll(' ', '_')}`)
              }
            }else{
              this.current.categoryName = null
              this.current.categoryProducts = []
            }
            this.loadingCategories = false
          }
        },
        computed:
        {
        }
    }
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>