<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-500 to-indigo-800 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Dodaj użytkownika</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Stwórz nowe konto systemowe.</p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Dane logowania</div>
                        <div class="select-none flex flex-col">
                            <label for="email" class="block text-sm font-medium text-gray-700">Email*</label>
                            <div class="mt-1">
                                <input type="email" maxlength="35"  name="email" v-model="newUser.contact.email" id="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. ty@gmail.com" />
                            </div>
                            <span class="text-xs select-none text-gray-400 mt-1">Brak możliwości późniejszej zmiany.</span>
                            <span class="text-xs select-none text-red-500" v-if="errors.email"> Sprawdź poprawność pola.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Hasło*</label>
                            <div class="mt-1">
                                <input type="password" v-model="password" maxlength="20" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Hasło" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.password"> Pole musi zawierać minimum 6 znaków.</span>
                        </div>
                    </div>
                    <div class="px-4 sm:px-6 mt-4">
                        <div class="mb-3 font-medium">Informacje</div>
                        <div class="select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Imię*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Jan" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.name"> Pole nie może być puste.</span>
                        </div>
                        <div class="mt-4 select-none">
                            <label for="pass" class="block text-sm font-medium text-gray-700">Nazwisko*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.surname" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="np. Kowalski" />
                            </div>
                            <span class="text-xs select-none text-red-500" v-if="errors.surname"> Pole nie może być puste.</span>
                            <!-- <span class="text-xs select-none text-gray-500"> Minimum 6 znaków.</span> -->
                        </div>
                        <div class="mt-4 select-none">
                            <label for="email" class="block text-sm font-medium text-gray-700">Telefon komórkowy*</label>
                            <div class="mt-1">
                                <input type="text" maxlength="20" v-model="newUser.contact.phone" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Numer telefonu z kierunkowym" />
                            </div>
                            <!-- <span class="text-xs select-none text-red-500" v-if="errors.phone"> Poprawny format to +XXXXXXXXXXX lub XXXXXXXXX.</span> -->
                            <span class="text-xs select-none text-red-500" v-if="errors.phone"> Uzupełnij numer telefonu</span>
                        </div>

                        <div class="mt-4">
                        <label for="email" class="block text-sm font-medium text-gray-700">Zdjęcie</label>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0 sm:col-span-3" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                    </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p v-if="this.errors.image" class="text-xs text-red-500 mt-0.5 ml-1">Musisz wybrać zdjęcie.</p>
                        </dd>
                      </div>
                    </div>
 
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                  <button @click.prevent="emitClose" v-if="!loading" class="transition-all duration-300 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                  <!-- <button ref="validateButton" @click.prevent="validateUser" v-if="!loading" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Dodaj</button> -->
                  <button ref="validateButton" @click.prevent="validateUser" v-if="!loading" type="button" class="transition-all duration-300 ml-2 inline-flex items-center gap-x-2 rounded-md bg-indigo-500 py-2 px-4  text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Dodaj
                    <UserAddIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon, UserAddIcon } from '@heroicons/vue/solid';
    import axios from 'axios';
    import {db, storage} from "@/firebase/gfbconf.js";
    export default {
        name: "ClientsModal",
        components: {
          Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, QuestionMarkCircleIcon, UserAddIcon
        },
        data: function () {
            return {
              loading: false,
              errors: {
                email: false,
                password: false,
                name: false,
                surname: false,
                phone: false,
              },
              open: false,
              password: "",
              newUser: {
                active: true,
                name: "",
                surname: "",
                contact: {
                    email: "",
                    phone: ""
                },
                userImage: null,
                id: null,
              },


              fileToUpload: null,
              imgPreviewSrc: "",
              fileLimitSize: 2097152,
              image: {
                createdDate: null,
                imageRef: null,
                imageUrl: "",
              },
            }
        },
        methods: {
          filenameGenerator(length)
            {
                let result           = `user_${this.newUser.name}_${this.newUser.surname}_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
          dropFile(e)
          {
              if(e.dataTransfer.files.length>0)
              {
                  if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                  {
                      if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                      {
                          this.fileToUpload = e.dataTransfer.files[0]
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(e.dataTransfer.files[0]);
                          
                          fileReader.addEventListener("load", () =>
                          {
                              this.imgPreviewSrc = fileReader.result;
                          })
                      }
                      else
                      {
                          this.$store.commit('setNotification',{
                              show: true,
                              head: "Zdjęcie waży powyżej 2MB",
                              subheader: `Plik jest zbyt ciężki.`,
                              success: false
                          }); 
                      }
                  }
                  else
                  {
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Plik nie jest zdjęciem.",
                          subheader: `Plik powinien być zdjęciem.`,
                          success: false
                      });   
                  }
              }
              
          },
          setMainImage()
          {
              if(this.$refs.mainImgUploader.files.length>0)
              {
                  if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                  {
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                      fileReader.addEventListener("load", () =>
                      {
                          this.imgPreviewSrc = fileReader.result;
                      })
                  }
                  else
                  {
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Zdjęcie waży powyżej 2MB",
                          subheader: `Plik jest zbyt ciężki.`,
                          success: false
                      });                    
                  }
              }
          },
          triggerImgClick()
          {
              this.$refs.mainImgUploader.click();
          },
          clearImg()
          {
              this.imgPreviewSrc = '';
              this.$refs.mainImgUploader.value = "";
              this.fileToUpload = null
          },
          async uploadNewImageToGallery(){
              if(this.fileToUpload === null){
                if(this.$refs.mainImgUploader.files[0] !== undefined){
                  this.fileToUpload = this.$refs.mainImgUploader.files[0]
                }
              }
              if(this.fileToUpload !== null)
              {
                  const storageRef = storage.ref();
                  const filename = this.filenameGenerator(10);
                  const path = `usersImages/${filename}`;
                  const imageRef = storageRef.child(path);
                  await imageRef.put(this.fileToUpload);
                  const fileUrl = await imageRef.getDownloadURL();
                  this.image.imageUrl = fileUrl;
                  this.image.imageRef = path;
              }
              this.image.createdDate = new Date()
        },
          callNotificationOnError(translatedCode)
          {
            this.emitClose();
            this.$store.commit('setNotification',{
              show: true,
              head: "Akcja zakończona niepowodzeniem!",
              subheader: translatedCode,
              success: false
            });
          },
          checkAutheError(code)
          {
            switch (code) {
              case 'auth/email-already-in-use':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/email-already-exists':
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                break;
              case 'auth/invalid-email':
                this.callNotificationOnError('E-mail jest niepoprawny.');
                break;
              case 'auth/operation-not-allowed':
                this.callNotificationOnError('Operacja zakazana przez system autoryzacji.');
                break;
              case 'auth/weak-password':
                this.callNotificationOnError('Hasło jest zbyt słabe. Sugerowane hasło powinno być dłuższe niż 6 znaków oraz zawierać znaki specjalne.');
                break;
              default:
                console.log(code);
                break;
            }   
          },
          resetErrors()
          {
            // Reset all errors
            this.errors.email = false;
            this.errors.password = false;
            this.errors.name =  false;
            this.errors.surname = false;
            this.errors.phone = false;
          },
          async addUser()
          {
            this.loading = true;
            try{
              this.newUser.contact.email = this.newUser.contact.email.toLowerCase();
              const checkForUsrInDb = await db.collection("UsersData").where("contact.email", "==", this.newUser.contact.email).get();
              if(checkForUsrInDb.empty)
              {
                if(this.fileToUpload === null){
                  if(this.$refs.mainImgUploader.files[0] !== undefined){
                    this.fileToUpload = this.$refs.mainImgUploader.files[0]
                  }
                }
                if(this.fileToUpload !== null)
                {
                  await this.uploadNewImageToGallery()
                  this.newUser.userImage = this.image
                }
                const result = await axios.post(`https://europe-central2-wbtankrestaurant.cloudfunctions.net/api/newUser`, {
                  user: this.newUser,
                  password: this.password
                });
                if(result.data.success)
                {
                  this.emitClose();
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem!",
                    subheader: "Użytkownik został utworzony.",
                    success: true
                  });
                  this.loading = false;
                }
                else
                {
                  this.checkAutheError(result.data.log);
                  this.loading = false;
                }
              }
              else
              {
                this.callNotificationOnError('Email jest już w użyciu przez innego użytkownika.');
                this.loading = false;
              }
              // const usr = await auth.createUserWithEmailAndPassword(this.newUser.contact.email, this.password);
              
            }
            catch(error)
            {
              console.log(error);
              this.loading = false;
            }
            
          },
          userValidator()
          {
            let errorsAmount = 0;
            this.resetErrors();
            if(this.newUser.contact.email === 0)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.newUser.contact.email.toLowerCase()) === false)
            {
              errorsAmount++;
              this.errors.email = true;
            }
            if(this.password.length < 6)
            {
              errorsAmount++;
              this.errors.password = true;
            }
            if(this.newUser.name.length === 0)
            {
              errorsAmount++;
              this.errors.name = true;
            }
            if(this.newUser.surname.length === 0)
            {
              errorsAmount++;
              this.errors.surname = true;
            }
            // if(/^\+?[1-9][0-9]{7,14}$/.test(this.newUser.contact.phone) === false)
            // {
            //   errorsAmount++;
            //   this.errors.phone = true;
            // }
            if(this.newUser.contact.phone.length === 0)
            {
              errorsAmount++;
              this.errors.phone = true;
            }

            return errorsAmount;

          },
          async validateUser()
          {
            const errors = this.userValidator();
            if(errors > 0) return;
            this.addUser();
          },
          emitClose()
          {
            this.resetErrors();
            this.$emit("closeClientModal");
            this.clearData();
          },
          clearData()
          {
              this.password = "";
              this.newUser = {
                    active: true,
                    name: "",
                    surname: "",
                    contact: {
                        email: "",
                        phone: ""
                    },
                    id: null,
                    userImage: null
                }       
              this.fileToUpload = null
              this.imgPreviewSrc = ""
              this.image = {
                createdDate: null,
                imageRef: null,
                imageUrl: ""
              }     
          }
          // Create new client
        },
        computed:{

        }
    };
</script>