<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">

            <div class="block md:hidden">
              <div class="rounded-md bg-yellow-50 p-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-yellow-800">Uwaga!</h3>
                      <div class="mt-1 text-sm text-yellow-700">
                        <p>Zarządzanie produktami możliwe jest tylko na ekranach o szerokości powyżej 768px.</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="shadow hidden md:block" v-if="!loadingCategories">
                <!-- {{categories}} -->
                <div class="flex border-b border-gray-200 bg-gray-100 shadow">
                    <div class="w-3/12">
                        <div class="w-full px-2 py-2 border-r border-gray-200">Kategorie</div>
                    </div>
                    <div class="w-4/12">
                        <div class="w-full px-2 py-2 border-r border-gray-200">Produkty</div>
                    </div>
                    <div class="w-5/12">
                        <div class="w-full px-2 py-2 flex justify-between">
                          <div>Edycja</div>
                          <div v-if="this.current.editProductObject !== null">
                            <PencilAltIcon v-if="!this.current.editMode" @click.prevent="this.current.editMode = true" class="transition ease-in-out duration-200 h-5 w-5 cursor-pointer text-gray-600 hover:text-blue-500" aria-hidden="true"/>
                            <CheckIcon v-if="this.current.editMode" @click.prevent="offEditMode()" class="transition ease-in-out duration-200 h-5 w-5 cursor-pointer text-gray-600 hover:text-blue-500" aria-hidden="true"/>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="flex h-96">
                    <div class="w-3/12 h-full flex flex-col bg-white overflow-y-scroll">
                        <div @click.prevent="changeCategory(category)" v-for="(category, index) in categories" :key="index" :class="['flex justify-between items-center transition ease-in-out duration-200 text-sm w-full px-2 py-2 border-b border-gray-100 shadow', category.id === current.categoryId ? 'bg-blue-200 cursor-default hover:bg-blue-200' : index % 2 === 0 ? 'bg-white cursor-pointer hover:bg-blue-100' : 'bg-gray-50 cursor-pointer hover:bg-blue-100']">
                          <div class="truncate">
                            <span class="opacity-20 text-xs">{{category.sortIndex}}</span> 
                            {{category.categoryName}}
                          </div>
                          <div v-if="!changeInProgress" :class="['flex']">
                            <ArrowUpIcon @click.stop="changeSortIndex(index, index-1)" :class="{'transition ease-in-out duration-200 text-gray-500 hover:text-gray-700 h-4 w-4 ml-1 cursor-pointer': true, 'opacity-10 pointer-events-none' : index === 0}" aria-hidden="true" />
                            <ArrowDownIcon @click.stop="changeSortIndex(index, index+1)" :class="{'transition ease-in-out duration-200 text-gray-500 hover:text-gray-700 h-4 w-4 ml-1 cursor-pointer': true, 'opacity-10 pointer-events-none' : index === (categories.length-1)}" aria-hidden="true" />
                            <TrashIcon @click.stop="deleteCategoryModalOn(index)" class="transition ease-in-out duration-200 h-4 w-4 ml-1 text-red-400 hover:text-red-600 cursor-pointer" aria-hidden="true" />
                          </div>
                          <div v-else>
                            <LoaderData class="py-2" />
                          </div>
                          </div>
                        <div @click.prevent="newCategory = true" :class="['transition ease-in-out duration-200 text-sm flex items-center w-full px-2 py-2 shadow cursor-pointer bg-purple-400 text-white hover:opacity-80']"> Utwórz nową kategorię <FolderAddIcon class="h-5 w-5 text-gray-50 ml-1.5" aria-hidden="true" /></div>
                    </div>
                    <div class="w-4/12 h-full flex flex-col bg-white overflow-y-scroll">
                        <div @click.prevent="changeProduct(product)" v-for="(product, index) in Object.keys(current.categoryProducts)" :key="index" :class="['flex justify-between items-center transition ease-in-out duration-200 truncate w-full text-sm px-2 py-2 border-b border-gray-100 shadow', product === current.pickedProductId ? 'bg-blue-200 cursor-default hover:bg-blue-200' : index % 2 === 0 ? 'bg-white cursor-pointer hover:bg-blue-100' : 'bg-gray-50 cursor-pointer hover:bg-blue-100']">
                          <div class="truncate">
                            {{current.categoryProducts[product].productName}}
                          </div>
                          <div v-if="!changeInProgress" :class="['flex']">
                            <TrashIcon @click.stop="deleteProductModalOn(product)" class="transition ease-in-out duration-200 h-4 w-4 ml-1 text-red-400 hover:text-red-600 cursor-pointer" aria-hidden="true" />
                          </div>
                        </div>
                        <div @click.prevent="newProduct = true" :class="['transition ease-in-out duration-200 text-sm flex items-center w-full px-2 py-2 shadow cursor-pointer bg-purple-400 text-white hover:opacity-80']"> Utwórz nowy produkt <DocumentAddIcon class="h-5 w-5 text-gray-50 ml-1.5" aria-hidden="true" /></div>
                    </div>
                    <div class="w-5/12 h-full flex flex-col bg-white overflow-y-scroll overflow-x-hidden">
                    <!-- {{this.current.editProductObject}} -->
                      <div v-if="this.current.editProductObject !== null" class="border-t border-gray-100">
                        <dl class="divide-y divide-gray-100">
                          <div v-if="this.current.editProductObject.productType !== undefined" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Typ produktu:</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <span v-if="this.current.editProductObject.productType === 'set'" class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700">Zestaw</span>
                              <span v-if="this.current.editProductObject.productType === 'standard'" class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700">Standardowy</span>
                            </dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Nazwa:</dt>
                            <dd v-if="!this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{this.current.editProductObject.productName}}</dd>
                            <dd v-if="this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <label for="productName" class="sr-only">Nazwa produktu</label>
                                <input type="text" name="productName" id="productName" v-model="this.current.editProductObject.productName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa produktu" />
                                <button @click.prevent="updateProduct('productName')" v-if="!updating && this.current.editProductObject.productName !== this.current.categoryProducts[this.current.pickedProductId].productName" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Aktualizuj
                                </button>
                                <LoaderData v-if="updating" class="py-2 mt-2" />
                              </div>
                            </dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Opis:</dt>
                            <dd v-if="!this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{this.current.editProductObject.description}}</dd>
                            <dd v-if="this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <label for="description" class="sr-only">Nazwa produktu</label>
                                <input type="text" name="description" id="description" v-model="this.current.editProductObject.description" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Opis produktu" />
                                <button @click.prevent="updateProduct('description')" v-if="!updating && this.current.editProductObject.description !== this.current.categoryProducts[this.current.pickedProductId].description" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Aktualizuj
                                </button>
                                <LoaderData v-if="updating" class="py-2 mt-2" />
                              </div>
                            </dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Cena /szt:</dt>
                            <dd v-if="!this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{this.current.editProductObject.unitPrice.toFixed(2)}} PLN</dd>
                            <dd v-if="this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <div>
                                <label for="unitPrice" class="sr-only">Nazwa produktu</label>
                                <!-- <input type="text" name="unitPrice" id="unitPrice" v-model="this.current.editProductObject.unitPrice" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Opis produktu" /> -->
                                <div class="flex max-w-lg">
                                    <div class="mt-0.5 relative rounded-md shadow-sm">
                                     <input type="number" @change="checkPrice()" v-model="this.current.editProductObject.unitPrice" name="price" id="price" class="flex-1 block w-full py-1.5 pl-3 pr-0 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Cena za sztukę" aria-describedby="price-currency" />
                                    </div>
                                    <div class="border border-l-0 block mt-0.5 pl-2 pr-3 py-1.5 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                      PLN
                                    </div>
                                </div>
                                <button @click.prevent="updateProduct('unitPrice')" v-if="!updating && this.current.editProductObject.unitPrice !== this.current.categoryProducts[this.current.pickedProductId].unitPrice" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Aktualizuj
                                </button>
                                <LoaderData v-if="updating" class="py-2 mt-2" />
                              </div>
                            </dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Zdjęcie:</dt>
                            <dd v-if="!this.current.editMode" class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <div class="aspect-h-4 aspect-w-3 bg-gray-50 border border-gray-200 rounded-md p-3 sm:aspect-none sm:h-32 sm:w-32">
                                  <img :src="this.current.editProductObject.imageUrl" class="h-full w-full object-cover object-center sm:h-full sm:w-full" />
                                </div>
                            </dd>
                            <dd v-if="this.current.editMode" class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0 sm:col-span-3" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex flex-col items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                        <button @click.prevent="updateProduct('image')" v-if="!updating && this.current.categoryProducts[this.current.pickedProductId].imageUrl !== this.imgPreviewSrc" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                          <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                          Aktualizuj
                                        </button>
                                        <LoaderData v-if="updating" class="py-2 mt-2" />
                                    </div>
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex flex-col items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xxs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xxs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </dd>
                          </div>
                          <div v-if="this.current.editProductObject.productType !== undefined && this.current.editProductObject.productType === 'set'" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Produkty do wyboru:</dt>
                            <dd v-if="!this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <fieldset>
                                  <div class="mt-1 max-h-56 overflow-auto divide-y divide-gray-100">
                                    <div v-for="(product, indx) in this.current.editProductObject.availableSetProducts" :key="indx" :class="['transition ease-in-out duration-200 relative flex items-start py-2', indx % 2 ? 'bg-gray-50' : '']">
                                      <div class="min-w-0 flex-1 text-sm leading-6">
                                        <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-8 w-8 rounded-full inline-block mr-3 object-cover object-center" />
                                        <span class="font-medium text-gray-600 text-xxs">{{ product.setProductName }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </dd>
                            <dd v-if="this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <fieldset>

                                  <div class="mt-1 max-h-56 overflow-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                                    <div v-for="(product, indx) in this.setProducts" :key="indx" class="relative flex items-start py-3">
                                      <div class="min-w-0 flex-1 text-sm leading-6">
                                        <img v-if="product.imageUrl !== undefined && product.imageUrl.length > 0" :src="product.imageUrl" class="h-5 w-5 rounded-full inline-block mr-3 object-cover object-center" />
                                        <label :for="`product-${product.setProductName}`" class="select-none text-xxs font-medium text-gray-600">{{ product.setProductName }}</label>
                                      </div>
                                      <div class="ml-3 flex h-6 items-center">
                                        <input @click="changePickedSetProduct(indx, $event)" v-model="product.picked" :id="`product-${product.setProductName}`" :name="`product-${product.setProductName}`" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              <button @click.prevent="updateProduct('setProducts')" v-if="!updating && !this.checkAvailableProducts()" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Aktualizuj
                                </button>
                                <LoaderData v-if="updating" class="py-2 mt-2" />
                            </dd>
                          </div>
                          <div v-if="this.current.editProductObject.productType !== undefined && this.current.editProductObject.productType === 'set'" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Liczba produktów wybieranych przez klienta:</dt>
                            <dd v-if="!this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <fieldset>
                                  <div class="mt-1 max-h-56 overflow-auto divide-y divide-gray-100">
                                    <div v-for="(slot, indx) in this.current.editProductObject.slots" :key="indx" :class="['transition ease-in-out duration-200 relative flex items-start py-1 px-2', indx % 2 ? 'bg-gray-50' : '']">
                                      <div class="min-w-0 flex-1 text-sm">
                                        <span class="font-medium text-gray-600 text-xxs">Wybór klienta nr {{ indx + 1 }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </dd>
                            <dd v-if="this.current.editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              <div>
                                <fieldset>
                                  <div class="mt-1 overflow-auto divide-y divide-gray-200 border-b border-t border-gray-200">
                                    <div v-for="(slot, indx) in this.current.editProductObject.slots" :key="indx" :class="['transition ease-in-out duration-200 relative flex items-start py-2 px-2', indx % 2 ? 'bg-gray-50' : '']">
                                      <div class="min-w-0 flex items-center justify-between flex-1 text-sm leading-6">
                                        <span class="font-medium text-gray-600 text-xxs ">Wybór klienta nr {{ indx + 1 }}</span>
                                        <TrashIcon v-if="this.current.editProductObject.slots.length > 1" @click="this.current.editProductObject.slots.splice(0,1)" class="transition ease-in-out duration-200 h-4 w-4 ml-1 text-red-400 hover:text-red-600 cursor-pointer" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-full mt-2 mb-2 flex flex-col items-center justify-center">
                                        <button type="button" @click.prevent="this.current.editProductObject.slots.push({picked: false, product: null})" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Dodaj slot</button>
                                  </div>
                                </fieldset>
                              </div>
                              <button @click.prevent="updateProduct('slots')" v-if="!updating && (this.current.editProductObject.slots.length !== this.current.categoryProducts[this.current.pickedProductId].slots.length)" type="button" class="mt-1 inline-flex items-center gap-x-1.5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                  <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Aktualizuj
                                </button>
                                <LoaderData v-if="updating" class="py-2 mt-2" />
                            </dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-900">Sprzedano:</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{this.current.editProductObject.soldAmount}}</dd>
                          </div>
                        </dl>
                      </div>
                      <div v-if="this.current.editProductObject === null">
                        <EmptyState ></EmptyState>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <LoadingSpinnerHub />
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <!-- <button type="button" :class="['transition ease-in-out duration-300 bg-blue-600 text-base font-medium hover:bg-blue-700 focus:ring-blue-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm']" @click.prevent="generateStatement()">Generuj</button> -->
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">Zamknij</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <NewProductModal v-if="newProduct" @accept="reloadCategories()" :categoryId="current.categoryId" @closeModal="newProduct = false"></NewProductModal>
  <NewCategoryModal v-if="newCategory" @accept="reloadCategories()" :newCategorySortIndex="categories.length" @closeModal="newCategory = false"></NewCategoryModal>
  <askForCategoryDelete v-if="deleteCategoryAsk" @accept="deleteCategory" :modalData="modalDataCategory" @closeModal="deleteCategoryAsk = false, deleteCategoryIndx = null"></askForCategoryDelete>
  <askForCategoryDelete v-if="deleteProductAsk" @accept="deleteProduct" :modalData="modalDataProduct" @closeModal="deleteProductAsk = false, deleteProductId = null"></askForCategoryDelete>
</template>

<script>
import moment from 'moment';
import LoaderData from '../components/LoaderData.vue';
import EmptyState from '@/components/EmptyState.vue';
import NewProductModal from '@/components/NewProductModal.vue';
import NewCategoryModal from '@/components/NewCategoryModal.vue';
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import askForCategoryDelete from '@/components/AlertModal.vue';
import { ref } from 'vue'
import {db, firebase, storage} from "@/firebase/gfbconf.js";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
    ArrowUpIcon,
    ArrowDownIcon,
    TrashIcon,
    PencilAltIcon,
    DocumentAddIcon,
    FolderAddIcon,
    CheckIcon,
    ExclamationIcon
    } from '@heroicons/vue/outline'
export default {
  components: {
    ExclamationIcon,
    askForCategoryDelete,
    LoaderData,
    EmptyState,
    NewProductModal,
    NewCategoryModal,
    ArrowUpIcon,
    ArrowDownIcon,
    TrashIcon,
    PencilAltIcon,
    CheckIcon,
    DocumentAddIcon,
    FolderAddIcon,
    LoadingSpinnerHub,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: [],
  data() {
    return {
        setProducts: [],
        updating: false,
        fileToUpload: null,
        imgExternalInputValue: "",
        imgPreviewSrc: "",
        showImgInputField: false,
        fileLimitSize: 2097152,
        modalDataCategory: {
            headerText: "Usuwanie kategorii wraz z produktami",
            bodyText: "Czy na pewno chcesz usunąć kategorię i jej produkty? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        modalDataProduct: {
            headerText: "Usuwanie produktu",
            bodyText: "Czy na pewno chcesz usunąć wybrany produkt? Usunięcie jest permanentne i nieodwracalne.",
            acceptButtonText: "Usuń"
        },
        deleteCategoryAsk: false,
        deleteCategoryIndx: null,
        deleteProductAsk: false,
        deleteProductId: null,
        changeInProgress: false,
        newProduct: false,
        newCategory: false,
        current: {
            categoryName: "",
            categoryId: "",
            categoryProducts: null,
            pickedProductId: null,
            editProductObject: null,
            editMode: false
        },
        categories: [],
        loadingCategories: true,
    }
  },
  methods: {
    checkAvailableProducts(){
      const actual = this.current.categoryProducts[this.current.pickedProductId].availableSetProducts.length
      const actualEdit = this.current.editProductObject.availableSetProducts.length
      let editFine = 0
      for (let i = 0; i < this.current.editProductObject.availableSetProducts.length; i++) {
        const element = this.current.editProductObject.availableSetProducts[i];
        for (let x = 0; x < this.current.categoryProducts[this.current.pickedProductId].availableSetProducts.length; x++) {
          const element2 = this.current.categoryProducts[this.current.pickedProductId].availableSetProducts[x];
          if(element.setProductName === element2.setProductName){
            editFine++
          }
        }
      }
      let toReturn = true

      if(actualEdit !== actual){
        toReturn = false
      }else{
        if(actual === editFine){
          toReturn = true
        }else{
          toReturn = false
        }
      }
      if(this.current.editProductObject.availableSetProducts.length === 0){
        toReturn = true
      }

      return toReturn
    },
    changePickedSetProduct(index, event){
      this.setProducts[index].picked = event.target.checked
      if(event.target.checked === true){
        this.current.editProductObject.availableSetProducts.push(this.setProducts[index])
      }else{
        for (let i = 0; i < this.current.editProductObject.availableSetProducts.length; i++) {
          const element = this.current.editProductObject.availableSetProducts[i];
          if(element.setProductName === this.setProducts[index].setProductName){
            this.current.editProductObject.availableSetProducts.splice(i,1)
          }
        }
      }
    },
    resetSetProduct(){
      for (let i = 0; i < this.setProducts.length; i++) {
        const element = this.setProducts[i];
        element.picked = false
        for (let x = 0; x < this.current.categoryProducts[this.current.pickedProductId].availableSetProducts.length; x++) {
          const elementFromProduct = this.current.categoryProducts[this.current.pickedProductId].availableSetProducts[x];
          if(element.setProductName === elementFromProduct.setProductName){
            element.picked = true
          }
        }
      }
    },
    async removeImage(ref){
      const mainImageRef = storage.ref().child(ref);
      try {
        await mainImageRef.delete();
      } catch (error) {
        console.log(error)
      }
    },
    filenameGenerator(length)
            {
                let result           = `prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
    dropFile(e)
    {
        if(e.dataTransfer.files.length>0)
        {
            if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
            {
                if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                {
                    this.fileToUpload = e.dataTransfer.files[0]
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.dataTransfer.files[0]);
                    
                    fileReader.addEventListener("load", () =>
                    {
                        this.imgPreviewSrc = fileReader.result;
                    })
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zdjęcie waży powyżej 2MB",
                        subheader: `Plik jest zbyt ciężki.`,
                        success: false
                    }); 
                }
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Plik nie jest zdjęciem.",
                    subheader: `Plik powinien być zdjęciem.`,
                    success: false
                });   
            }
        }
        
    },
    replaceImgWithExternalLink()
    {
        if(this.imgExternalInputValue.length === 0) return;
        this.clearImg();
        this.product.imageRef = null;
        this.product.imageUrl = this.imgExternalInputValue;
        this.imgPreviewSrc = this.imgExternalInputValue;
        this.imgExternalInputValue = "";
        this.showImgInputField = false;
    },
    toggleOtherSourcePhoto()
    {
        this.clearImg();
        this.product.imageRef = null;
        this.product.imageUrl = "";
        this.showImgInputField = !this.showImgInputField;
    },
    setMainImage()
    {
        if(this.$refs.mainImgUploader.files.length>0)
        {
            if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
            {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                fileReader.addEventListener("load", () =>
                {
                    this.imgPreviewSrc = fileReader.result;
                })
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zdjęcie waży powyżej 2MB",
                    subheader: `Plik jest zbyt ciężki.`,
                    success: false
                });                    
            }
        }
    },
    triggerImgClick()
    {
        this.$refs.mainImgUploader.click();
    },
    clearImg()
    {
        this.imgPreviewSrc = '';
        this.$refs.mainImgUploader.value = "";
    },
    async deleteProduct(){
      if(this.deleteProductId !== null){
      this.changeInProgress = true
      this.removeImage(this.current.categoryProducts[this.deleteProductId].imageRef)
      await db.collection("ProductCategories").doc(this.current.categoryId).update({
            [`products.${this.deleteProductId}`]: firebase.firestore.FieldValue.delete()
          })
      this.reloadCategories()
      this.changeInProgress = false
      }
    },
    deleteProductModalOn(product){
      this.deleteProductId = product
      this.deleteProductAsk = true
    },
    deleteCategoryModalOn(index){
      this.deleteCategoryIndx = index
      this.deleteCategoryAsk = true
    },
    async deleteCategory(){
      if(this.deleteCategoryIndx !== null){
      this.changeInProgress = true
      let categoryToDelete = this.categories[this.deleteCategoryIndx]
      for (let y = 0; y < Object.keys(this.categories[this.deleteCategoryIndx].products).length; y++) {
        const productId = Object.keys(this.categories[this.deleteCategoryIndx].products)[y]
        this.removeImage(this.categories[this.deleteCategoryIndx].products[productId].imageRef)
      }
      // for (let x = 0; x < Object.keys(element.products).length; x++) {
            // const productId = Object.keys(element.products)[x]
            // const product = element.products[productId];
      for (let i = 0; i < this.categories.length; i++) {
        if(i > this.deleteCategoryIndx){
          await db.collection("ProductCategories").doc(this.categories[i].id).update({
            sortIndex: this.categories[i].sortIndex-1
          })
        }
      }
      await db.collection("ProductCategories").doc(categoryToDelete.id).delete()
      this.reloadCategories()
      this.changeInProgress = false
      }
    },
    async changeSortIndex(currentSortIndex, newSortIndex){
      this.changeInProgress = true
      let first = this.categories[currentSortIndex]
      let second = this.categories[newSortIndex]
      
      const firstBackupIndex = first.sortIndex
      const secondBackupIndex = second.sortIndex

      await db.collection("ProductCategories").doc(this.categories[currentSortIndex].id).update({
        sortIndex: secondBackupIndex
      })
      await db.collection("ProductCategories").doc(this.categories[newSortIndex].id).update({
        sortIndex: firstBackupIndex
      })

      first.sortIndex = secondBackupIndex
      second.sortIndex = firstBackupIndex

      this.categories.sort((a,b) => a.sortIndex - b.sortIndex)
      this.changeInProgress = false
    },
    reloadCategories(){
      this.downloadCategories()
      this.$emit("reload");
    },
    offEditMode(){
      this.current.editProductObject = JSON.parse(JSON.stringify(this.current.categoryProducts[this.current.pickedProductId]))
      this.current.editMode = false
    },
    checkPrice()
          {
              if(this.current.editProductObject.unitPrice.length === 0)
              {
                  this.current.editProductObject.unitPrice = 1;
              }
              if(this.current.editProductObject.unitPrice < 1)
              {
                  this.current.editProductObject.unitPrice = 1;
              }
              else
              {
                  this.current.editProductObject.unitPrice = parseFloat(this.current.editProductObject.unitPrice);
              }
          },
    async updateProduct(type){
      this.updating = true
      if(type === 'slots'){
        await db.collection("ProductCategories").doc(this.current.categoryId).update({
          [`products.${this.current.pickedProductId}.slots`]: this.current.editProductObject.slots
        })
        this.current.categoryProducts[this.current.pickedProductId].slots = this.current.editProductObject.slots
        this.$store.commit('setNotification',{
            show: true,
            head: "Zaktualizowano produkt!",
            subheader: `Liczba produktów do wyboru została poprawnie zaktualizowana.`,
            success: true
        }); 
      }
      if(type === 'setProducts'){
        for (let i = 0; i < this.current.editProductObject.availableSetProducts.length; i++) {
          const element = this.current.editProductObject.availableSetProducts[i];
          element.createdDate = moment.unix(element.createdDate.seconds).toDate()
        }
        await db.collection("ProductCategories").doc(this.current.categoryId).update({
              [`products.${this.current.pickedProductId}.availableSetProducts`]: this.current.editProductObject.availableSetProducts
            })
        this.current.categoryProducts[this.current.pickedProductId].availableSetProducts = this.current.editProductObject.availableSetProducts
        this.$store.commit('setNotification',{
            show: true,
            head: "Zaktualizowano produkt!",
            subheader: `Produkty do wyboru zostały poprawnie zaktualizowane.`,
            success: true
        }); 
      }
      if(type === 'productName'){
        await db.collection("ProductCategories").doc(this.current.categoryId).update({
              [`products.${this.current.pickedProductId}.productName`]: this.current.editProductObject.productName
            })
        this.current.categoryProducts[this.current.pickedProductId].productName = this.current.editProductObject.productName
        this.$store.commit('setNotification',{
            show: true,
            head: "Zaktualizowano produkt!",
            subheader: `Nazwa produktu została poprawnie zaktualizowana.`,
            success: true
        }); 
      }
      if(type === 'description'){
        await db.collection("ProductCategories").doc(this.current.categoryId).update({
              [`products.${this.current.pickedProductId}.description`]: this.current.editProductObject.description
            })
        this.current.categoryProducts[this.current.pickedProductId].description = this.current.editProductObject.description
        this.$store.commit('setNotification',{
            show: true,
            head: "Zaktualizowano produkt!",
            subheader: `Opis produktu został poprawnie zaktualizowany.`,
            success: true
        }); 
      }
      if(type === 'unitPrice'){
        await db.collection("ProductCategories").doc(this.current.categoryId).update({
              [`products.${this.current.pickedProductId}.unitPrice`]: this.current.editProductObject.unitPrice
            })
        this.current.categoryProducts[this.current.pickedProductId].unitPrice = this.current.editProductObject.unitPrice
        this.$store.commit('setNotification',{
            show: true,
            head: "Zaktualizowano produkt!",
            subheader: `Cena produktu została poprawnie zaktualizowana.`,
            success: true
        }); 
      }
      if(type === 'image'){
        await this.removeImage(this.current.categoryProducts[this.current.pickedProductId].imageRef)
        if(this.fileToUpload === null){
          this.fileToUpload = this.$refs.mainImgUploader.files[0]
        }
        let imageUrlToUpload = null;
        let imageRefToUpload = null;
        if(this.fileToUpload !== null)
        {
            const storageRef = storage.ref();
            const filename = this.filenameGenerator(10);
            const path = `products/${filename}`;
            const imageRef = storageRef.child(path);
            await imageRef.put(this.fileToUpload);
            const fileUrl = await imageRef.getDownloadURL();
            imageUrlToUpload = fileUrl;
            imageRefToUpload = path;
        }
        this.fileToUpload = null
        if(imageRefToUpload !== null && imageUrlToUpload !== null){
          await db.collection("ProductCategories").doc(this.current.categoryId).update({
            [`products.${this.current.pickedProductId}.imageRef`]: imageRefToUpload,
            [`products.${this.current.pickedProductId}.imageUrl`]: imageUrlToUpload,
              })
          this.current.editProductObject.imageRef = imageRefToUpload
          this.current.editProductObject.imageUrl = imageUrlToUpload
          this.imgPreviewSrc = imageUrlToUpload
          this.current.categoryProducts[this.current.pickedProductId].imageRef = this.current.editProductObject.imageRef
          this.current.categoryProducts[this.current.pickedProductId].imageUrl = this.current.editProductObject.imageUrl
          this.$store.commit('setNotification',{
              show: true,
              head: "Zaktualizowano produkt!",
              subheader: `Zdjęcie produktu zostało poprawnie zaktualizowane.`,
              success: true
          }); 
        }else{
          this.$store.commit('setNotification',{
              show: true,
              head: "Nie udało się zaktualizować produktu!",
              subheader: `Zdjęcie produktu nie zostało zaktualizowane.`,
              success: false
          }); 
        }
      }
      this.updating = false
    },
    changeProduct(product){
        this.current.pickedProductId = product
        this.current.editProductObject = JSON.parse(JSON.stringify(this.current.categoryProducts[product]))
        this.imgPreviewSrc = JSON.parse(JSON.stringify(this.current.editProductObject)).imageUrl
        this.current.editMode = false
        if(this.current.categoryProducts[product].productType !== undefined && this.current.categoryProducts[product].productType === 'set'){
          this.resetSetProduct()
        }
    },
    changeCategory(category){
        this.current.categoryName = category.categoryName
        this.current.categoryId = category.id
        this.current.categoryProducts = category.products
        if(Object.keys(category.products).length > 0){
          this.current.pickedProductId = Object.keys(category.products)[0]
          this.current.editProductObject = JSON.parse(JSON.stringify(this.current.categoryProducts[this.current.pickedProductId]))
          this.imgPreviewSrc = JSON.parse(JSON.stringify(this.current.editProductObject)).imageUrl
          if(this.current.categoryProducts[this.current.pickedProductId].productType !== undefined && this.current.categoryProducts[this.current.pickedProductId].productType === 'set'){
            this.resetSetProduct()
          }
        }else{
          this.current.pickedProductId = null
          this.current.editProductObject = null
        }
        this.current.editMode = false
    },
    async downloadCategories(){
        this.loadingCategories = true
        this.categories = []
        this.current.categoryName = ""
        this.current.categoryId = ""
        this.current.categoryProducts = null
        let categoriesDocs = await db.collection("ProductCategories").get()
        let result = await db.collection("setProducts").doc('setProducts').get()
        let data = result.data()
        this.setProducts = data.setProducts
        for (let i = 0; i < this.setProducts.length; i++) {
          const element = this.setProducts[i];
          element.picked = false
        }
        for (let i = 0; i < categoriesDocs.docs.length; i++) {
            const element = categoriesDocs.docs[i].data();
            if(element.id === undefined){
              element.id = categoriesDocs.docs[i].id
            }
            this.categories.push(element)
        }
        this.categories.sort((a,b) => a.sortIndex - b.sortIndex)
        if(this.categories[0] !== undefined){
          this.current.categoryName = this.categories[0].categoryName
          this.current.categoryId = this.categories[0].id
          this.current.categoryProducts = this.categories[0].products
          if(Object.keys(this.categories[0].products).length > 0){
          this.current.pickedProductId = Object.keys(this.categories[0].products)[0]
          this.current.editProductObject = JSON.parse(JSON.stringify(this.categories[0].products[this.current.pickedProductId]))
          this.imgPreviewSrc = JSON.parse(JSON.stringify(this.current.editProductObject)).imageUrl
          // this.imgPreviewSrc = this.imgPreviewSrc.imageUrl
          if(this.current.categoryProducts[this.current.pickedProductId].productType !== undefined && this.current.categoryProducts[this.current.pickedProductId].productType === 'set'){
            this.resetSetProduct()
          }
          }else{
            this.current.pickedProductId = null
            this.current.editProductObject = null
          }
        }else{
          this.current.categoryName = null
          this.current.categoryId = null
          this.current.categoryProducts = []
          this.current.pickedProductId = null
          this.current.editProductObject = null
        }
        this.current.editMode = false
        this.loadingCategories = false
    },
    log(log){
        console.log(log)
    },
    emitClose()
    {
        this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    },
  },
  computed: {
  },
  created(){
    this.downloadCategories()
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  }
}
</script>

<style scoped>


</style>
