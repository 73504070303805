<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">

            <div class="px-1 py-3 sm:px-6 flex items-center">
              <div>
                <h3 class="text-xl font-semibold leading-7 text-gray-900">Tworzenie koszyka</h3>
                <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Przygotuj swój koszyk pod wymagania klienta.</p>
              </div>
            </div>

            <div v-if="step === 1" class="p-4 pt-0">
              <div @click.prevent="selectTypeOfCart(cart.name, cart.id)" v-for="cart in carts" :key="cart.id" :class="['transition-all duration-300 cursor-pointer group relative flex items-center gap-x-6 mb-2 rounded-lg p-4 text-sm leading-6', cart.selected ? 'bg-green-100 hover:bg-green-50' : 'hover:bg-gray-50']">
                <div :class="['flex h-11 w-11 flex-none items-center justify-center rounded-lg', cart.selected ? 'bg-gray-50' : `bg-${cart.iconColor}-50`]">
                  <component v-if="!cart.selected" :is="cart.icon" :class="['h-6 w-6 text-gray-600', cart.selected ? 'text-gray-600' : `text-${cart.iconColor}-600`]" aria-hidden="true" />
                  <CheckCircleIcon v-if="cart.selected" :class="[cart.selected ? 'text-green-500' : '', 'h-6 w-6 text-gray-600']" aria-hidden="true" />
                </div>
                <div class="flex-auto">
                  <a class="block font-semibold text-gray-900">
                    {{ cart.name }} <span class="font-light">{{cart.explanation}}</span>
                    <span class="absolute inset-0" />
                  </a>
                  <p :class="['mt-1', cart.descriptionColor !== 'default' ? `text-${cart.descriptionColor}-600` : 'text-gray-600']">{{ cart.description }}</p>
                </div>
              </div>
            </div>

            <div v-if="step === 2" class="sm:p-6 sm:pb-4 sm:pt-0">
              <div class="mx-auto max-w-lg">

                <div class="rounded-md bg-yellow-50 p-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-yellow-800">Uwaga!</h3>
                      <div class="mt-1 text-sm text-yellow-700">
                        <p>Przygotowane nazwy posiłków widoczne są w nagłówkach na ofercie cateringowej.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="pl-0.5 pb-1">
                    <h2 class="mt-2 text-sm font-medium text-gray-700">Przygotuj podział na posiłki</h2>
                  </div>
                  <form action="#" class="flex">
                    <input v-model="mealName" type="text" name="meal" id="meal" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nazwa posiłku np. Deser" />
                    <button @click.prevent="addMeal()" type="submit" class="ml-4 flex-shrink-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj</button>
                  </form>
                  <div class="text-xs pl-0.5 pt-1 text-red-500">
                    <span v-if="this.errors.mealNameExsist">Podana nazwa już istnieje.</span>
                    <span v-if="this.errors.mealNameLength">Maksymalna długość nazwy posiłku to 40 znaków.</span>
                  </div>
                </div>
                <div class="mt-4">
                  <h3 class="text-sm font-medium text-gray-500">Utworzony podział</h3>
                  <ul v-if="preparedMeals.length > 0" role="list" class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                    <li v-for="(meal, index) in preparedMeals" :key="index" class="flex items-center justify-between space-x-3 py-4">
                      <div class="flex min-w-0 flex-1 items-center space-x-3">
                        <div class="min-w-0 truncate flex-1 text-sm">
                          <span class="mr-1.5 opacity-50"> {{index + 1}}</span>
                          <span class="truncate text-sm font-medium text-gray-900">{{meal}}</span>
                        </div>
                      </div>
                      <div class="flex-shrink-0">
                        <button @click.prevent="removeMeal(meal)" type="button" class="inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-red-600">
                          <TrashIcon class="h-5 w-5 text-red-7s00" aria-hidden="true" />
                          Usuń
                        </button>
                      </div>
                    </li>
                  </ul>
                  <EmptyState class="mt-1" v-if="preparedMeals.length === 0" />
                </div>
              </div>
            </div>
  
             <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse ">
              <!-- <button v-if="step === 2" @click="emitAccept(this.selectedType, this.preparedMeals)" :disabled="preparedMeals.length === 0" type="button" :class="['transition-all duration-300 sm:ml-2 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600', preparedMeals.length === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-500']">
                Stwórz koszyk
              </button> -->
              <button v-if="step === 2" @click="emitAccept(this.selectedType, this.preparedMeals)" :disabled="preparedMeals.length === 0" type="button" :class="['transition-all duration-300 sm:ml-2 mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:mt-0 sm:w-auto sm:text-sm', preparedMeals.length === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-500']">
                <!-- <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" /> -->
                Stwórz koszyk
              </button>
              <button v-if="step < 2 && step !== 2" @click="step++" type="button" :disabled="selectedType === null" :class="['transition-all duration-300 sm:ml-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:mt-0 sm:w-auto sm:text-sm', selectedType !== null ? 'hover:bg-indigo-500' : 'cursor-not-allowed opacity-40']">
                Dalej
              </button>
              <!-- <button v-if="step > 1" @click="step--"  type="button" class="transition-all duration-300 sm:ml-2 inline-flex items-center gap-x-2 rounded-md bg-red-500 border border-red-600 px-4 py-2 text-sm text-white font-medium shadow-sm hover:bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                Poprzedni krok
              </button> -->
              <button v-if="step > 1" @click="step--"  type="button" class="transition-all duration-300 sm:ml-2 mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 border border-red-600 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:mt-0 sm:w-auto sm:text-sm">
                Poprzedni krok
              </button>
              <button @click.prevent="close" type="button" class="transition-all duration-300 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="close" ref="cancelButtonRef">
                Anuluj
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckCircleIcon, ExclamationIcon, DocumentAddIcon,OfficeBuildingIcon,ShoppingBagIcon,TrashIcon } from '@heroicons/vue/outline'
import EmptyState from '@/components/EmptyState.vue';
export default {
  data() {
    return {
      open: true,
      mealName: "",
      preparedMeals: [],
      step: 1,
      carts: [
        // AVAILABLE COLORS:
        // black // white // gray // red // yellow // green // blue // indigo // purple // slate // pink // teal
        { id: 0, type: "bankiet", name: 'Bankiet', explanation: '(w restauracji)', description: 'Brak dodatkowych kosztów', descriptionColor: 'default', icon: OfficeBuildingIcon, iconColor: 'indigo', selected: false },
        { id: 1, type: "catering", name: 'Catering', explanation: '(na wynos)', description: 'Zawiera kaucje za naczynia', descriptionColor: 'red', icon: ShoppingBagIcon, iconColor: 'yellow', selected: false  },
      ],
      selectedType: null,
      errors: {
        mealNameLength: false,
        mealNameExsist: false,
      }
    }
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    DocumentAddIcon,
    CheckCircleIcon,
    OfficeBuildingIcon,
    ShoppingBagIcon,
    TrashIcon,
    EmptyState
  },
  props: ['modalData', 'buttonColor', 'iconColor'],
  methods: {
    removeMeal(meal){
      for (let i = 0; i < this.preparedMeals.length; i++) {
          let current = this.preparedMeals[i]
          if(current === meal){
            this.preparedMeals.splice(i, 1)
          }
        }
    },
    addMeal(){
      this.errors.mealNameLength = false
      this.errors.mealNameExsist = false
      this.mealName = this.mealName.charAt(0).toUpperCase() + this.mealName.slice(1)
      if(this.mealName.length > 0 && this.mealName.length < 41){
        let mealExsists = false
        for (let i = 0; i < this.preparedMeals.length; i++) {
          let current = this.preparedMeals[i]
          if(current.toUpperCase() === this.mealName.toUpperCase()){
            mealExsists = true
          }
        }
        if(mealExsists){
          this.errors.mealNameExsist = true
        }else{
          this.preparedMeals.push(this.mealName)
          this.mealName = ""
        }
      }else{
        this.errors.mealNameLength = true
      }
    },
    selectTypeOfCart(value, index)
    {
      for(let i=0; i<this.carts.length; i++)
      {
        if(this.carts[i].id === index)
        {
          this.carts[i].selected = true;
          this.selectedType = this.carts[i].type;
        }
        else
        {
          this.carts[i].selected = false;
        }
      }
    },
    emitClose()
    {
      setTimeout(() => {
        this.$emit("close");
      }, 500);
    },
    emitAccept(type, meals)
    {
      this.$emit("accept", type, meals);
      this.$emit("close");
    },
    close()
    {
        this.emitClose();
        this.open = false
    }
  },
}
</script>
